import { SlideSection } from "../../components/silde-section/slide-section.component";
import MissionBg from "../../assets/backgrounds/mission/home_slider.png";
import { Section } from "../../components/section/section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import { uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { IPageContent, TextContent } from "../../components/main-layout/text-content/text-content";
import parse from "html-react-parser";
import { Wrap } from "../../components/wrap/wrap.component";
import {
  CultureCoreValuesHeading,
  CultureCoreValuesImg,
  CultureCoreValuesMainDiv,
  CultureCoreValuesText,
} from "./culture.styles";
import difference from "../../assets/icons/difference.svg";
import success from "../../assets/icons/success.svg";
import takeControl from "../../assets/icons/take_control.svg";
import getThingsDone from "../../assets/icons/get_things_done.svg";

import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const CulturePage = () => {
  const { culture } = useSelector(uiDataWebsiteText);
  const ourCourValues = [
    {
      image: difference,
      heading: culture.abtus_cult_headone_subheadone,
      text: culture.abtus_cult_headone_subheadone_paraone,
      border: true,
    },
    {
      image: success,
      heading: culture.abtus_cult_headone_subheadtwo,
      text: culture.abtus_cult_headone_subheadtwo_paraone,
      border: true,
    },
    {
      image: takeControl,
      heading: culture.abtus_cult_headone_subheadthree,
      text: culture.abtus_cult_headone_subheadtwo_parathree,
      border: true,
    },
    {
      image: getThingsDone,
      heading: culture.abtus_cult_headone_subheadfour,
      text: culture.abtus_cult_headone_subheadtwo_parafour,
      border: false,
    },
  ];
  const isMobile = useMediaQuery("sm");
  const textValue: IPageContent[] = [
    {
      mainTitle: culture.abtus_cult_mainhead,
      article: [
        culture.abtus_cult_mainhead_paraone +
        " " +
        culture.abtus_cult_mainhead_paratwo +
        " " +
        culture.abtus_cult_mainhead_parathree +
        " " +
        culture.abtus_cult_mainhead_parafour,
      ],
    },
    { title: culture.abtus_cult_headone },
  ];

  return (
    <>
      <HelmetContainer metaData={culture.metaData} />
      <SlideSection
        bgImage={MissionBg}
        title={parse(culture.abtus_cult_mainhead_nxt + " <br> " + culture.abtus_cult_mainhead)}
        bgVariant={"gradient"}
        justify={"center"}
        titleAlign={"center"}
        titleWidth={"100%"}
        BgImageStyles={
          isMobile
            ? {
              backgroundRepeat: "no-repeat",
              backgroundPosition: "60%",
            }
            : {
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }
        }
      />
      <Section mainContent m={"9rem auto 19rem"}>
        <TextContent content={textValue} />
        {ourCourValues.map((ourCourValues) => {
          return (
            <CultureCoreValuesMainDiv border={ourCourValues.border}>
              <CultureCoreValuesImg src={ourCourValues.image} />
              <Wrap>
                <CultureCoreValuesHeading>{ourCourValues.heading}</CultureCoreValuesHeading>
                <CultureCoreValuesText>{ourCourValues.text}</CultureCoreValuesText>
              </Wrap>
            </CultureCoreValuesMainDiv>
          );
        })}
      </Section>
    </>
  );
};
