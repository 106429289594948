import React, { PropsWithChildren } from "react";
import { HelmetContainerProps } from "./helmetContainer.types";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { uiDataScripts } from "../../redux/uiData/selectors";


export const HelmetContainer: React.FC<PropsWithChildren<HelmetContainerProps>> = (props) => {
  const scripts = useSelector(uiDataScripts);

  const google_tag_id = scripts?.google_tag_id;
  const google_noscript_tag_id = scripts?.google_noscript_tag_id;
  const homePageScript = scripts?.homePageScript;
  const innerPageScript = scripts?.innerPageScript;

  const { homePage, metaData } = props;

  return (
    <Helmet>
      {/* <!--- Google Tag Manager ---> */}
      {google_tag_id && <script type="text/javascript" async >
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${google_tag_id}');`}
      </script>}
      {(homePage ? homePageScript : innerPageScript)?.map((scriptData: any) => {
        return (
          <script type="application/ld+json">
            {JSON.stringify(scriptData)}
          </script>
        )
      })}
      {parse((decodeURIComponent(metaData)))}

      {/* <!--- Google Tag Manager (noscript) ---> */}
      {google_noscript_tag_id && <noscript>
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=${google_noscript_tag_id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
      </noscript>}
    </Helmet>
  );
}