import styled from "styled-components";

export const FinancialStatementMapItems = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 160px;
`;
export const FinancialStatementText = styled.p`
  font-size: 22px;
  @media (max-width: 992px) {
    font-size: 18px;
  }
`;
