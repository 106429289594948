import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import React, { useMemo } from "react";

import { CryptoCurrencyPage } from "../../pages/crypto-currency/crypto-currency.page";
import { MainPage } from "../../pages/main/main.page";
import { RouteItemType } from "./app-router.types";
import { BankAccountsPage } from "../../pages/bank-accounts/bank-accounts.page";
import { MissionPage } from "../../pages/mission/mission.page";
import { CareersPage } from "../../pages/careers/careers.page";
import { SecurityPage } from "../../pages/security/security.page";
import { FeesPage } from "../../pages/fees/fees.page";
import { InvestorNewsPage } from "../../pages/investor_news/investor_news.page";
import { BlogPage } from "../../pages/blog/blog.page";
import { BlogArticlePage } from "../../pages/blog/blog-article.page";
import { ChooseAccountPage } from "../../pages/auth/choose-account/choose-account.page";
import { LoginPage } from "../../pages/auth/login/login.page";
import { RegisterPage } from "../../pages/auth/register/register.page";
import { ContactPage } from "../../pages/contact/contact.page";
import { NewsPage } from "../../pages/news/news.page";
import { PrivacyPolicyPage } from "../../pages/privacy-policy-terms/privacy-policy.page";
import { PolicesTermsPage } from "../../pages/privacy-policy-terms/policies-terms.page";
import { LeadershipPage } from "../../pages/leadership/leadership.page";
import { PersonPage } from "../../pages/leadership/person/person.page";
import { FinancialStatementsPage } from "../../pages/financial-statements/financial-statements.page";
import { ConductPage } from "../../pages/code-of-conduct/code-of-conduct.page";
import { CulturePage } from "../../pages/culture/culture.page";
import { RiskManagementPage } from "../../pages/r-management/r-management.page";
import { GovernancePage } from "../../pages/governance/governance.page";
import { SustainabilityPage } from "../../pages/sustainability/sustainability.page";
import { ModernSlavery } from "../../pages/modern-slavery/modern-slavery.page";
import { ComplaintPolicyPage } from "../../pages/complaints-policy/complaints-policy.page";
import { NotFound } from "../../pages/not-found/not-found.page";

export const loginURL = process.env.REACT_APP_LOGIN_ROUTE || "";
export const registerURL = process.env.REACT_APP_REGISTER_ROUTE || "";

const ExternalRedirect = ({ redirectURL }: { redirectURL: string }) => {
  console.log("test");
  window.location.replace(redirectURL);
  return <></>;
};
const InternalRedirect = ({ redirectURL }: { redirectURL: string }) => {
  window.location.href = redirectURL;
  return <></>;
};

const routesList: RouteItemType[] = [
  { path: "/", Component: <MainPage /> },
  { path: "/crypto_currency", Component: <CryptoCurrencyPage /> },
  { path: "/bank_accounts", Component: <BankAccountsPage /> },
  { path: "/mission", Component: <MissionPage /> },
  { path: "/careers", Component: <CareersPage /> },
  { path: "/security", Component: <SecurityPage /> },
  { path: "/login/", Component: <ExternalRedirect redirectURL={loginURL} /> },
  { path: "/register/", Component: <ExternalRedirect redirectURL={registerURL} /> },
  { path: "/about/mission/", Component: <InternalRedirect redirectURL="/mission" /> },
  { path: "/about/payment-security/", Component: <InternalRedirect redirectURL="/security" /> },
  { path: "/about/careers/", Component: <InternalRedirect redirectURL="/careers" /> },
  { path: "/accounts/", Component: <InternalRedirect redirectURL="/bank_accounts" /> },
  { path: "/crypto-currency/", Component: <InternalRedirect redirectURL="/crypto_currency" /> },
  { path: "/fees/", Component: <FeesPage /> },
  { path: "/mediacenter/News/", Component: <InternalRedirect redirectURL="/news" /> },
  { path: "/contact/", Component: <ContactPage /> },
  { path: "/legal-disclaimer/", Component: <InternalRedirect redirectURL="/terms" /> },
  { path: "/privacy_policy", Component: <PrivacyPolicyPage /> },
  { path: "/privacy-policy/", Component: <InternalRedirect redirectURL="/privacy_policy" /> },
  { path: "/not-found/", Component: <NotFound /> },
  {
    path: "/fees",
    nestedRoutes: [
      { path: ":feeId", Component: <FeesPage /> },
      { index: true, Component: <FeesPage /> },
    ],
  },
  {
    path: "/investor_news",
    nestedRoutes: [
      { index: true, Component: <InvestorNewsPage /> },
      { path: "news/:id", Component: <BlogArticlePage /> },
    ],
  },

  {
    path: "/news",
    nestedRoutes: [
      { Component: <NewsPage />, index: true },
      { Component: <BlogArticlePage />, path: "article/:id" },
    ],
  },
  { path: "/modern_slavery_policy", Component: <ModernSlavery /> },
  { path: "/complaint_policy", Component: <ComplaintPolicyPage /> },
  { path: "/terms", Component: <PolicesTermsPage /> },
  {
    path: "/leadership",
    nestedRoutes: [
      { Component: <LeadershipPage />, index: true },
      { path: ":id", Component: <PersonPage /> },
    ],
  },
  { path: "/financialStatements", Component: <FinancialStatementsPage /> },
  { path: "/codeOfConduct", Component: <ConductPage /> },
  { path: "/culture", Component: <CulturePage /> },
  { path: "/riskManagement", Component: <RiskManagementPage /> },
  { path: "/governance", Component: <GovernancePage /> },
  { path: "/sustainability", Component: <SustainabilityPage /> },
];

const getRoutes = (routes: RouteItemType[]) => {
  return routes.map((route, index) => {
    const { Component, nestedRoutes, ...rest } = route;
    if (nestedRoutes) {
      return (
        <Route key={`nested-${index}`} element={Component} {...rest}>
          {getRoutes(nestedRoutes)}
        </Route>
      );
    }
    return <Route element={Component} key={`route-${index}`} {...rest} />;
  });
};

export const AppRouter: React.FC = (): JSX.Element => {
  const routes = useMemo(() => getRoutes(routesList), []);
  return (
    <Routes>
      {routes}
      <Route path={"*"} element={<Navigate to={"/"} />} />
    </Routes>
  );
};
