import { SlideSection } from "../../components/silde-section/slide-section.component";
import MissionBg from "../../assets/backgrounds/mission/home_slider.png";
import { Section } from "../../components/section/section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import { RManagementContent, RManagementHeading, RManagementList, RManagementTitle, RManagementListContent } from "./modern-slavery.styles";
import { useSelector } from "../../redux/store";
import { uiDataWebsiteText } from "../../redux/uiData/selectors";

import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const ModernSlavery = () => {
  const isMobile = useMediaQuery("sm");
  const { slaveryPolicy } = useSelector(uiDataWebsiteText);

  return (
    <>
      <HelmetContainer metaData={slaveryPolicy.metaData} />
      <SlideSection
        bgImage={MissionBg}
        title={parse(slaveryPolicy.msp_sitename + "<br>" + slaveryPolicy.msp_mainhead)}
        bgVariant={"gradient"}
        justify={"center"}
        titleAlign={"center"}
        titleWidth={"90%"}
        BgImageStyles={
          isMobile
            ? {
              backgroundRepeat: "no-repeat",
              backgroundPosition: "60%",
            }
            : {
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }
        }
      />
      <Section mainContent m={"9rem auto 19rem"}>
        <RManagementTitle>{slaveryPolicy.msp_page_head}</RManagementTitle>
        <RManagementContent>{slaveryPolicy.msp_page_desc_one}</RManagementContent>
        <RManagementContent>{slaveryPolicy.msp_page_desc_two}</RManagementContent>
        <RManagementHeading>{slaveryPolicy.msp_secOne}</RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secOne_paraOne}</RManagementContent>
        <RManagementHeading>{slaveryPolicy.msp_secTwo}</RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secTwo_paraOne}</RManagementContent>
        <RManagementHeading>{slaveryPolicy.msp_secThree}</RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secThree_paraOne}</RManagementContent>
        <RManagementHeading>{slaveryPolicy.msp_secFour}</RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secFour_paraOne}</RManagementContent>
        <RManagementContent>{slaveryPolicy.msp_secFour_paraTwo}</RManagementContent>
        <RManagementList>
          <RManagementListContent>{slaveryPolicy.msp_secFour_paraTwo_pointOne}</RManagementListContent>
          <RManagementListContent >{slaveryPolicy.msp_secFour_paraTwo_pointTwo}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secFour_paraTwo_pointThree}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secFour_paraTwo_pointFour}</RManagementListContent>

        </RManagementList>
        <RManagementHeading>{slaveryPolicy.msp_secFive}</RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secFive_paraOne}</RManagementContent>
        <RManagementList>
          <RManagementListContent>{slaveryPolicy.msp_secFive_paraOne_pointOne}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secFive_paraOne_pointTwo}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secFive_paraOne_pointThree}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secFive_paraOne_pointFour}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secFive_paraOne_pointFive}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secFive_paraOne_pointSix}</RManagementListContent>
        </RManagementList>
        <RManagementHeading>{slaveryPolicy.msp_secSix}</RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secSix_paraOne}</RManagementContent>
        <RManagementList>
          <RManagementListContent>{slaveryPolicy.msp_secSix_paraOne_pointOne}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secSix_paraOne_pointTwo}</RManagementListContent>
          <RManagementListContent>{slaveryPolicy.msp_secSix_paraOne_pointThree}</RManagementListContent>
        </RManagementList>
        <RManagementContent>{slaveryPolicy.msp_secSix_paraTwo}</RManagementContent>
        <RManagementHeading>{slaveryPolicy.msp_secSeven}</RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secSeven_paraOne}</RManagementContent>
        <RManagementContent>{slaveryPolicy.msp_secSeven_paraTwo}</RManagementContent>
        <RManagementHeading>
          {slaveryPolicy.msp_secEight}
        </RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secEight_paraOne}</RManagementContent>
        <RManagementContent>{slaveryPolicy.msp_secEight_paraTwo}</RManagementContent>
        <RManagementContent>{slaveryPolicy.msp_secEight_paraThree}</RManagementContent>
        <RManagementHeading>{slaveryPolicy.msp_secNine}</RManagementHeading>
        <RManagementContent>{slaveryPolicy.msp_secNine_paraOne}</RManagementContent>

      </Section>
    </>
  );
};
