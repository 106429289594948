import styled from "styled-components";

export const TextMainContainer = styled.div`
  color: white;
`;
export const TextMainTitle = styled.h3`
  line-height: 3.8rem;
  text-align: center;
  margin-bottom: 40px;
`;
export const TextMiniTitle = styled.h5`
  margin-top: 2rem;
  line-height: 1.4em;
  font-size: 2.4rem;
  font-weight: 700;
`;
export const TextTitle = styled.h3`
  margin-top: 4rem;
  margin-bottom: 4rem;
  line-height: 3.8rem;
  font-size: 3.6rem;
  text-align: center;
`;
export const TextSubTitle = styled.span<{ italic?: boolean }>`
  font-size: 2.4rem;
  font-weight: bold;
  margin-top: 1.4rem;
  margin-right: 1rem;
  color: white;
  ${(props) =>
    props.italic &&
    `
    font-style: italic;
  `}
`;
export const TextDescription = styled.div<{ italic?: boolean; margin?: boolean }>`
  font-size: 20px;
  line-height: 1.4em;
  padding-top: 5px;
  text-align: justify;
  color: #A1A1A1;
  font-weight: 300;
  ${(props) =>
    props.italic === true &&
    `
    font-style: italic;
  `}
`;
