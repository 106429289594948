import { Wrap } from "../../components/wrap/wrap.component";
import {
  AlternateLinkText,
  AlternateLinks,
  ErrorContent,
  ErrorTitle,
  ErrorWrapper,
  Errortext,
  HomeLink,
  HomeText,
} from "./not-found.styles";

export const NotFound: React.FC = (): JSX.Element => {
  return (
    // <Section mainContent>
    <Wrap>
      <ErrorWrapper>
        <ErrorContent>
          <ErrorTitle>Sorry - Page Not Found</ErrorTitle>
          <Errortext>
            This could be the result of the page being removed, the name being changed or the page being temporarly
            unavailable. Alternatively you can try one of the following links:
          </Errortext>
          <HomeLink>
            <HomeText>Home</HomeText>
          </HomeLink>
          <AlternateLinks>
            <AlternateLinkText>About Us</AlternateLinkText>
            <AlternateLinkText>Media Center</AlternateLinkText>
            <AlternateLinkText>Contact Black Banx</AlternateLinkText>
          </AlternateLinks>
        </ErrorContent>
      </ErrorWrapper>
    </Wrap>
    // </Section>
  );
};
