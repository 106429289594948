import styled from "styled-components";

export const CultureCoreValuesMainDiv = styled.div<{ border: boolean }>`
  display: flex;
  align-items: center;
  padding: 40px 0px 40px 60px;
  border-bottom: ${(props) => (props.border ? "1px solid grey" : "")};
  column-gap: 60px;
  @media (max-width: 992px) {
    padding: 40px 0px 40px 0px;
  }
`;

export const CultureCoreValuesImg = styled.img`
  width: 140px;
  height: 150px;
`;

export const CultureCoreValuesHeading = styled.h4`
  margin-bottom: 10px;
  font-size: 28px;
  @media (max-width: 992px) {
    font-size: 24px;
  }
`;

export const CultureCoreValuesText = styled.p`
  font-size: 20px;
  text-align: justify;
  font-size: 20px;
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;
