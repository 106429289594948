import {
  CopyrightContainer,
  FooterLinkItem,
  FooterLinksRoot,
  FooterLinksGroup,
  FooterLinksTitle,
  FooterRoot,
  CopyrightText,
  FooterLinksMobile,
} from "./footer.styles";
import { FooterLinkGroup } from "./footer.types";
import React, { useEffect, useState } from "react";
import { Section } from "../../section/section.component";
import { SelectHandler, SelectOption } from "../../inputs/select/select.types";
import { useMediaQuery } from "../../../utils/use-media-query";
import { FooterConnect } from "./parts/connect.component";
import { useDispatch, useSelector } from "../../../redux/store";
import { uiDataLanguageList, uiDataWebsiteText } from "../../../redux/uiData/selectors";
import { setSelectedLanguage } from "../../../redux/uiData/slice";
import { Wrap } from "../../wrap/wrap.component";

export const Footer = () => {
  const { common } = useSelector(uiDataWebsiteText);
  const isMobile = useMediaQuery("sm");

  const languageList = useSelector(uiDataLanguageList);
  const dispatch = useDispatch();

  const [language, setLanguage] = useState<SelectOption | null>(null);

  const handleSetLanguage: SelectHandler = (e) => {
    const { value } = e.target;
    setLanguage(value);
    dispatch(setSelectedLanguage(value));
  };

  useEffect(() => {
    languageList && setLanguage(languageList[0]);
  }, [languageList]);

  const footerLinks: FooterLinkGroup[] = [
    {
      title: common.hf_foot_left_headOne,
      links: [
        { label: common.hf_head_one_rt, path: "/mission" },
        { label: common.hf_foot_left_lineEight, path: "/contact" },
        { label: common.hf_foot_left_lineTwo, path: "/culture" },
        { label: common.hf_foot_left_lineFive, path: "/careers" },
        { label: common.hf_head_one_rt_subfive, path: "/codeofconduct" },
        { label: common.hf_foot_left_lineFour, path: "/sustainability" },
        { label: common.hf_foot_left_lineSix, path: "/news" },
        { label: common.hf_foot_left_lineSeven, path: "/leadership" },
        { label: common.hf_foot_left_lineNine, path: "/financialStatements" },
        { label: common.hf_foot_left_lineTen, path: "/governance" },
        { label: common.hf_foot_left_lineEleven, path: "/riskManagement" },
        { label: common.hf_foot_left_lineTwelve, path: "/security" },
        
      ],
    },
    {
      title: common.hf_foot_mid_headOne,
      links: [
        { label: common.hf_foot_mid_headOne_lineOne, path: "/terms" },
        { label: common.hf_foot_mid_headOne_lineThree, path: "/complaint_policy" },
        { label: common.hf_foot_mid_headOne_lineFour, path: "/privacy_policy" },
        { label: common.hf_foot_mid_headOne_lineNine, path: "/modern_slavery_policy" },
      ],
    },
    {
      title: common.hf_foot_mid_headTwo,
      links: [
        { label: common.hf_foot_mid_headTwo_lineOne, path: "/fees/0" },
        { label: common.hf_foot_mid_headTwo_lineTwo, path: "/fees/1" },
        { label: common.hf_foot_mid_headTwo_lineThree, path: "/fees/2" },
        { label: common.hf_foot_mid_headTwo_lineFour, path: "/fees/3" },
      ],
    },
  ];
  return (
    <Section mainContent>
      <FooterRoot>
        {isMobile && <FooterConnect />}
        <FooterLinksRoot>
          {footerLinks.map((el, idx) => (
            <FooterLinksGroup key={`footer-link-group-${idx}`} maxContent>
              <Wrap sx={{ width: "100%" }}>
                <FooterLinksTitle>{el.title}</FooterLinksTitle>
              </Wrap>
              <FooterLinksMobile>
                {el.links.map((link, idx) => (
                  <FooterLinkItem to={link.path} key={`footer-link-${idx}`}>
                    {link.label}
                  </FooterLinkItem>
                ))}
              </FooterLinksMobile>
            </FooterLinksGroup>
          ))}
        </FooterLinksRoot>
        {!isMobile ? <FooterConnect /> : <></>}
      </FooterRoot>
      <CopyrightContainer>{common.hf_foot_right_lineTwo}</CopyrightContainer>
      <CopyrightContainer>
        {" "}
        <CopyrightText>{common.hf_foot_right_lineThree}</CopyrightText>{" "}
      </CopyrightContainer>
    </Section>
  );
};
