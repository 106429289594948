import { SlideSection } from "../../components/silde-section/slide-section.component";
import MissionBg from "../../assets/backgrounds/mission/home_slider.png";
import { Section } from "../../components/section/section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import { ComplaintContent, ComplaintHeading, ComplaintsPolicyHeading } from "./complaints-policy.styles";
import { useSelector } from "../../redux/store";
import { uiDataWebsiteText } from "../../redux/uiData/selectors";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const ComplaintPolicyPage = () => {
  const isMobile = useMediaQuery("sm");
  const { complaintsPolicy } = useSelector(uiDataWebsiteText);
  return (
    <>
      <HelmetContainer metaData={complaintsPolicy.metaData} />
      <SlideSection
        bgImage={MissionBg}
        title={parse(complaintsPolicy.cp_sitename + "<br>" + complaintsPolicy.cp_mainhead)}
        bgVariant={"gradient"}
        justify={"center"}
        titleAlign={"center"}
        titleWidth={"90%"}
        BgImageStyles={
          isMobile
            ? {
              backgroundRepeat: "no-repeat",
              backgroundPosition: "60%",
            }
            : {
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }
        }
      />
      <Section mainContent m={"9rem auto 19rem"}>
        <ComplaintsPolicyHeading>{complaintsPolicy.cp_mainhead}</ComplaintsPolicyHeading>
        <ComplaintHeading>{complaintsPolicy.cp_page_head}</ComplaintHeading>
        <ComplaintContent>{complaintsPolicy.cp_paraOne}</ComplaintContent>
        <ComplaintContent>{complaintsPolicy.cp_paraTwo}</ComplaintContent>
        <ComplaintContent>
          {complaintsPolicy.cp_paraThree}
        </ComplaintContent>
        <ComplaintContent>
          {complaintsPolicy.cp_paraFour}
        </ComplaintContent>
      </Section>
    </>
  );
};
