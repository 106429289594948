import React, { PropsWithChildren, useEffect, useState } from "react";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import MainBg from "../../assets/backgrounds/fees/fees_main_bg.png";
import { Section } from "../../components/section/section.component";
import { Accordeon } from "../../components/accordeon/accordeon.component";
import { Wrap } from "../../components/wrap/wrap.component";
import { FindCountry, PlanInfo } from "./fees.styles";
import { Divider } from "../../components/divider/divider.styles";
import TitaniumAccountBg from "../../assets/backgrounds/fees/plastic_card.png";
import { Input } from "../../components/inputs/input/input.component";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";
import { useMediaQuery } from "../../utils/use-media-query";
import { IconButton } from "../../components/icon-button/icon-button.component";
import { ReactComponent as ArrowIconLeft } from "../../assets/icons/arrow_left.svg";
import { ReactComponent as CheckMark } from "../../assets/icons/tick_light.svg";
import { ReactComponent as CrossMark } from "../../assets/icons/close_icon.svg";
import { PlanInfoType } from "./fees.types";
import { Transition } from "react-transition-group";
import { uiDataWebsiteText, uiDataCountryOfResidence } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

const duration = 100;

const defaultStyle = {
  transition: `all ${duration}ms, opacity ${duration - 50}ms`,
};

const transitionStyles: Record<string, any> = {
  entering: { transform: "translateX(-100%)", opacity: 0 },
  entered: { transform: "translateX(0)" },
  exiting: { transform: "translateX(100%)", opacity: 0 },
  exited: { transform: "translateX(100%)", opacity: 0 },
};

const transitionStylesReversed: Record<string, any> = {
  entering: { transform: "translateX(100%)", opacity: 0 },
  entered: { transform: "translateX(0)" },
  exiting: { transform: "translateX(-100%)", opacity: 0 },
  exited: { transform: "translateX(-100%)", opacity: 0 },
};

const Carousel: React.FC<PropsWithChildren<{ in: boolean; reversed?: boolean }>> = (props) => {
  const { in: inProp, reversed, children } = props;
  const dynamicStyles: Record<string, any> = !reversed ? transitionStyles : transitionStylesReversed;
  return (
    <Transition in={inProp} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...dynamicStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

const Card = ({ currentPlan }: { currentPlan: number }) => {
  const [hoverLocation, setHoverLocation] = useState("01");
  const numImages = 70;

  function handleHover(e: any) {
    const x = e.nativeEvent.offsetX;
    const width = e.currentTarget.offsetWidth;
    const country = Math.floor((numImages - 1) * (x / width));
    const inverseLocation = numImages - country - 1;

    const newHoverLocation = Math.floor(inverseLocation / 3 + 1)
      .toString()
      .padStart(2, "0");
    setHoverLocation(newHoverLocation);
  }

  const inverseLocation = (parseInt(hoverLocation) - 1) * 3;
  return (
    <>
      {[...Array(4)].map((item, index) => (
        <PlanInfo.Img
          src={`/cards/r360-card${index + 1}/card_spin360_${(inverseLocation + 1).toString().padStart(2, "0")}.png`}
          alt={"Plan info"}
          onMouseMove={handleHover}
          style={{
            display: currentPlan !== index ? "none" : "block",
          }}
        />
      ))}
    </>
  );
};

export const FeesPage: React.FC = (): JSX.Element => {
  const countryOfResidence = useSelector(uiDataCountryOfResidence);
  const { accountsFees } = useSelector(uiDataWebsiteText);
  const [currentPlan, setCurrentPlan] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [inProp, setInProp] = useState<boolean>(true);
  const [slideDirection, setSlideDirection] = useState<boolean>(false);
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");
  const { feeId } = useParams();
  const [country, setCountry] = useState("");
  const countryy = useSelector(uiDataCountryOfResidence);
  const [countryModal, setCountryModal] = useState<boolean>(false);
  const [countryTxt, setCountryTxt] = useState<string>("");

  const handleLocation = (value: string) => {
    setCountry(value);
  };

  const handleCountryClick = (index: number) => {
    setCountry("");
    if (!countryy[index].isRegisterBlock) {
      setCountryTxt(`Please proceed, we are offering accounts for clients based in ${countryy[index].name}`);
      setCountryModal(true);
    } else {
      setCountryTxt(`Sorry, we can not accept customers from ${countryy[index].name} at the moment.`);
      setCountryModal(true);
    }
  };

  // console.log({ meta: parse(decodeURIComponent(accountsFees.metaData)) })
  const promisedControl = (reverse: boolean, cb: () => void) => {
    const p = new Promise((res) => {
      setSlideDirection(reverse);
      res(null);
    });
    p.then(() => {
      return new Promise((res) => {
        setInProp(false);
        setTimeout(() => res(null), 200);
      });
    })
      .then(() => {
        return new Promise((res) => {
          cb();
          res(null);
        });
      })
      .then(() => setInProp(true));
  };

  const handleNextPlan = () => {
    promisedControl(true, () => setCurrentPlan((prev) => prev + 1));
  };

  const handlePrevPlan = () => {
    promisedControl(false, () => setCurrentPlan((prev) => prev - 1));
  };
  const handleFilter = (e: any) => {
    console.log(e);
    //@ts-ignore
    const searchWord = e.target.value.toLowerCase();
    const country = countryOfResidence
      .filter((el: any) => el)
      .trim()
      .toLowerCase()
      .includes(searchWord.trim().toLowerCase());
    console.log(country);
  };
  const planInfoItems: PlanInfoType[] = [
    {
      title: accountsFees.ac_fee_typslde_hdone,
      imgSrc: TitaniumAccountBg,
      prosItems: [
        {
          title: accountsFees.ac_fee_typslde_hdone_subhdone,
          description: [accountsFees.ac_fee_typslde_hdone_subhdone_pone],
        },
        {
          title: accountsFees.ac_fee_typslde_hdone_subhdtwo,
          description: [
            accountsFees.ac_fee_typslde_hdone_subhdtwo_pone,
            accountsFees.ac_fee_typslde_hdone_subhdtwo_ptwo,
            accountsFees.ac_fee_typslde_hdone_subhdtwo_pthree,
            accountsFees.ac_fee_typslde_hdone_subhdtwo_pfour,
            accountsFees.ac_fee_typslde_hdone_subhdtwo_pfive,
            accountsFees.ac_fee_typslde_hdone_subhdtwo_psix,
            accountsFees.ac_fee_typslde_hdone_subhdtwo_pseven,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdone_subhdfour,
          description: [
            accountsFees.ac_fee_typslde_hdone_subhdfour_pone,
            accountsFees.ac_fee_typslde_hdone_subhdfour_ptwo,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdone_subhdthree,
          description: [
            accountsFees.ac_fee_typslde_hdone_subhdthree_pone,
            accountsFees.ac_fee_typslde_hdone_subhdthree_ptwo,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdone_subhdfive,
          description: [accountsFees.ac_fee_typslde_hdone_subhdfive_pone],
        },
        {
          title: accountsFees.ac_fee_typslde_hdone_subhdSix,
          description: [
            accountsFees.ac_fee_typslde_hdone_subhdsix_pone,
            accountsFees.ac_fee_typslde_hdone_subhdsix_ptwo,
            accountsFees.ac_fee_typslde_hdone_subhdsix_pthree,
            accountsFees.ac_fee_typslde_hdone_subhdsix_pfour,
            accountsFees.ac_fee_typslde_hdone_subhdsix_pfive,
            accountsFees.ac_fee_typslde_hdone_subhdsix_psix,
            accountsFees.ac_fee_typslde_hdone_subhdsix_pseven,
            accountsFees.ac_fee_typslde_hdone_subhdsix_peight,
          ],
        },
      ],
    },
    {
      title: accountsFees.ac_fee_typslde_hdtwo,
      imgSrc: TitaniumAccountBg,
      prosItems: [
        {
          title: accountsFees.ac_fee_typslde_hdtwo_subhdone,
          description: [accountsFees.ac_fee_typslde_hdtwo_subhdone_pone],
        },
        {
          title: accountsFees.ac_fee_typslde_hdtwo_subhdtwo,
          description: [
            accountsFees.ac_fee_typslde_hdtwo_subhdtwo_pone,
            accountsFees.ac_fee_typslde_hdtwo_subhdtwo_ptwo,
            accountsFees.ac_fee_typslde_hdtwo_subhdtwo_pthree,
            accountsFees.ac_fee_typslde_hdtwo_subhdtwo_pfour,
            accountsFees.ac_fee_typslde_hdtwo_subhdtwo_pfive,
            accountsFees.ac_fee_typslde_hdtwo_subhdtwo_psix,
            accountsFees.ac_fee_typslde_hdtwo_subhdtwo_pseven,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdtwo_subhdthree,
          description: [
            accountsFees.ac_fee_typslde_hdtwo_subhdthree_pone,
            accountsFees.ac_fee_typslde_hdtwo_subhdthree_pthree,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdtwo_subhdfour,
          description: [
            accountsFees.ac_fee_typslde_hdtwo_subhdfour_pone,
            accountsFees.ac_fee_typslde_hdtwo_subhdfour_pthree,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdtwo_subhdfive,
          description: [accountsFees.ac_fee_typslde_hdtwo_subhdfive_pone],
        },
        {
          title: accountsFees.ac_fee_typslde_hdtwo_subhdsix,
          description: [
            accountsFees.ac_fee_typslde_hdtwo_subhdSix_pone,
            accountsFees.ac_fee_typslde_hdtwo_subhdSix_ptwo,
            accountsFees.ac_fee_typslde_hdtwo_subhdSix_pthree,
            accountsFees.ac_fee_typslde_hdtwo_subhdSix_pthree_nxt,
            accountsFees.ac_fee_typslde_hdtwo_subhdSix_pfour,
            accountsFees.ac_fee_typslde_hdtwo_subhdSix_pfour_nxt,
            accountsFees.ac_fee_typslde_hdtwo_subhdSix_pfive,
            accountsFees.ac_fee_typslde_hdtwo_subhdSix_psix,
          ],
        },
      ],
    },
    {
      title: accountsFees.ac_fee_typslde_hdthree,
      imgSrc: TitaniumAccountBg,
      prosItems: [
        {
          title: accountsFees.ac_fee_typslde_hdthree_subhdone,
          description: [accountsFees.ac_fee_typslde_hdthree_subhdone_pone],
        },
        {
          title: accountsFees.ac_fee_typslde_hdthree_subhdtwo,
          description: [
            accountsFees.ac_fee_typslde_hdthree_subhdtwo_pone,
            accountsFees.ac_fee_typslde_hdthree_subhdtwo_ptwo,
            accountsFees.ac_fee_typslde_hdthree_subhdtwo_pthree,
            accountsFees.ac_fee_typslde_hdthree_subhdtwo_pfour,
            accountsFees.ac_fee_typslde_hdthree_subhdtwo_pfive,
            accountsFees.ac_fee_typslde_hdthree_subhdtwo_psix,
            accountsFees.ac_fee_typslde_hdthree_subhdtwo_pseven,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdthree_subhdthree,
          description: [
            accountsFees.ac_fee_typslde_hdthree_subhdthree_pone,
            accountsFees.ac_fee_typslde_hdthree_subhdthree_ptwo,
            accountsFees.ac_fee_typslde_hdthree_subhdthree_pthree,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdthree_subhdfour,
          description: [
            accountsFees.ac_fee_typslde_hdthree_subhdfour_pone,
            accountsFees.ac_fee_typslde_hdthree_subhdfour_ptwo,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdthree_subhdfive,
          description: [
            accountsFees.ac_fee_typslde_hdthree_subhdfive_pone,
            accountsFees.ac_fee_typslde_hdthree_subhdfive_ptwo,
            accountsFees.ac_fee_typslde_hdthree_subhdfive_pthree,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdthree_subhdsix,
          description: [
            accountsFees.ac_fee_typslde_hdthree_subhdsix_pone,
            accountsFees.ac_fee_typslde_hdthree_subhdsix_ptwo,
            accountsFees.ac_fee_typslde_hdthree_subhdsix_pthree,
            accountsFees.ac_fee_typslde_hdthree_subhdSix_pthree_nxt,
            accountsFees.ac_fee_typslde_hdthree_subhdsix_pfour,
            accountsFees.ac_fee_typslde_hdthree_subhdsix_pfive,
            accountsFees.ac_fee_typslde_hdthree_subhdsix_psix,
            accountsFees.ac_fee_typslde_hdthree_subhdSix_pseven,
            accountsFees.ac_fee_typslde_hdthree_subhdSix_peight,
          ],
        },
      ],
    },
    {
      title: accountsFees.ac_fee_typslde_hdfour,
      imgSrc: TitaniumAccountBg,
      prosItems: [
        {
          title: accountsFees.ac_fee_typslde_hdfour_subhdone,
          description: [accountsFees.ac_fee_typslde_hdfour_subhdone_pone],
        },
        {
          title: accountsFees.ac_fee_typslde_hdfour_subhdtwo,
          description: [
            accountsFees.ac_fee_typslde_hdfour_subhdtwo_pone,
            accountsFees.ac_fee_typslde_hdfour_subhdtwo_ptwo,
            accountsFees.ac_fee_typslde_hdfour_subhdtwo_pthree,
            accountsFees.ac_fee_typslde_hdfour_subhdtwo_pfour,
            accountsFees.ac_fee_typslde_hdfour_subhdtwo_pfive,
            accountsFees.ac_fee_typslde_hdfour_subhdtwo_psix,
            accountsFees.ac_fee_typslde_hdfour_subhdtwo_pseven,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdfour_subhdthree,
          description: [
            accountsFees.ac_fee_typslde_hdfour_subhdthree_pone,
            accountsFees.ac_fee_typslde_hdfour_subhdthree_ptwo,
            accountsFees.ac_fee_typslde_hdfour_subhdthree_pthree,
            accountsFees.ac_fee_typslde_hdfour_subhdthree_pfour,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdfour_subhdfour,
          description: [
            accountsFees.ac_fee_typslde_hdfour_subhdfour_pone,
            accountsFees.ac_fee_typslde_hdfour_subhdfour_ptwo,
            accountsFees.ac_fee_typslde_hdfour_subhdfour_pthree,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdfour_subhdfive,
          description: [
            accountsFees.ac_fee_typslde_hdfour_subhdfive_pone,
            accountsFees.ac_fee_typslde_hdfour_subhdfive_ptwo,
            accountsFees.ac_fee_typslde_hdfour_subhdfive_pthree,
          ],
        },
        {
          title: accountsFees.ac_fee_typslde_hdfour_subhdsix,
          description: [
            accountsFees.ac_fee_typslde_hdfour_subhdsix_pone,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_ptwo,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_pthree,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_pfour,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_pfive,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_pSix,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_pseven,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_peight,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_pnine,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_pten,
            accountsFees.ac_fee_typslde_hdfour_subhdsix_peleven,
          ],
        },
      ],
    },
  ];
  const countries: { country: string }[] = [
    { country: accountsFees.ac_fee_lower_drp_dflt_one },
    { country: accountsFees.ac_fee_lower_drp_dflt_two },
    { country: accountsFees.ac_fee_lower_drp_dflt_three },
    { country: accountsFees.ac_fee_lower_drp_dflt_four },
    { country: accountsFees.ac_fee_lower_drp_dflt_five },
  ];

  useEffect(() => {
    if (feeId) setCurrentPlan(+feeId);
  }, [feeId]);

  useEffect(() => {
    const handleBodyClick = () => {
      setCountry("");
      setSearchValue("");
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const renderResults = () => {
    return (
      <>
        <Wrap
          sx={{
            width: "100%",
            marginBottom: "1rem",
            position: "absolute",
            top: "8rem",
            zIndex: 20,
          }}
        >
          <FindCountry.Result>
            {countryy
              .map((item: any, index: number) => {
                return { ...item, index };
              })
              .filter((item: any, index: any) => item.name?.toLowerCase().includes(country?.toLowerCase()))
              .map((item: any, index: any, arr: any) => {
                return (
                  <>
                    <Wrap
                      onClick={() => {
                        handleCountryClick(item.index);
                      }}
                      sx={{
                        cursor: "pointer",
                        ...(index !== arr.length - 1
                          ? {
                              borderBottom: "1px solid white",
                              marginBottom: "1.5rem",
                              paddingBottom: "10px",
                              cursor: "pointer",
                            }
                          : {}),
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{item.name} </div>
                      {!item.isRegisterBlock ? <CheckMark width={"24px"} /> : <CrossMark width={"24px"} />}
                      {/* <br /> */}
                    </Wrap>
                  </>
                );
              })}
          </FindCountry.Result>
        </Wrap>
      </>
    );
  };

  return (
    <>
      <HelmetContainer metaData={accountsFees.metaData} />
      <SlideSection
        bgImage={MainBg}
        title={parse(accountsFees.ac_fee_hdone + " <br> " + accountsFees.ac_fee_hdone_sub)}
        bgVariant={"gradient"}
        titleAlign={"center"}
        justify={"center"}
        BgImageStyles={isMobile ? { backgroundSize: "cover", backgroundPosition: "40%" } : { backgroundSize: "cover" }}
      />

      <Section mainContent m={isMobile ? "0rem auto 20rem" : "15rem auto"} style={{ overflow: "hidden" }}>
        <Wrap
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            marginBottom: "40px",
          }}
        >
          <Wrap sx={{ marginRight: "2rem", widht: "120%", flex: "4" }}>{accountsFees.ac_fee_hdone_acc}</Wrap>
          <Wrap sx={{ marginRight: "2rem" }}>
            <IconButton disabled={currentPlan === 0} onClick={handlePrevPlan}>
              <ArrowIconLeft />
            </IconButton>
          </Wrap>

          <Wrap sx={{ flex: "1" }}>
            <IconButton disabled={currentPlan === planInfoItems.length - 1} reverse onClick={handleNextPlan}>
              <ArrowIconLeft />
            </IconButton>
          </Wrap>
        </Wrap>
        <Carousel in={inProp} reversed={slideDirection}>
          <PlanInfo.Root>
            <PlanInfo.Description>
              <Wrap>
                <Wrap sx={{ display: "flex", marginBottom: "2.4rem" }}>
                  {!isMobile && <PlanInfo.Title>{planInfoItems[currentPlan].title}</PlanInfo.Title>}
                </Wrap>
                {planInfoItems[currentPlan].prosItems.map((pros, idx) => (
                  <Wrap key={`plan-info-${idx}`}>
                    <Accordeon title={pros.title} description={pros.description} />
                    {idx !== planInfoItems[currentPlan].prosItems.length - 1 && (
                      <Wrap sx={{ margin: "2.4rem 0" }}>
                        <Divider />
                      </Wrap>
                    )}
                  </Wrap>
                ))}
              </Wrap>
              {isMobile && <PlanInfo.Title>{planInfoItems[currentPlan].title}</PlanInfo.Title>}
              <Wrap
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxHeight: "50rem",
                }}
              >
                <Card currentPlan={currentPlan} />
              </Wrap>
            </PlanInfo.Description>
          </PlanInfo.Root>
        </Carousel>
      </Section>

      <Section mainContent m={"0 auto 20rem"}>
        <FindCountry.Root>
          <Wrap
            sx={{
              gridArea: "country",
              display: "flex",
              flexDirection: "column",
              marginBottom: isMobile ? "6rem" : "inherit",
              gap: isTablet ? "2rem" : "0",
            }}
          >
            {countries.map((country, idx) => {
              const { Item } = FindCountry.Countries;
              return <Item key={country.country + idx}>{`${country.country}`}</Item>;
            })}
          </Wrap>
          {isMobile && (
            <>
              <Wrap
                sx={{
                  gridArea: "search",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  margin: isMobile ? "5rem 0" : "inherit",
                  position: "relative",
                }}
              >
                {country && renderResults()}
                <Input
                  fullWidth
                  value={searchValue}
                  startIcon={<SearchIcon />}
                  placeholder={accountsFees.ac_fee_typslde_subfoot_searchtxt}
                  onChange={(e) => handleLocation(e.target.value)}
                  helperText={accountsFees.ac_fee_typslde_subfoot_searchtxthint}
                />
              </Wrap>
            </>
          )}
          <Wrap sx={{ gridArea: "title" }}>
            <FindCountry.Title>{accountsFees.ac_fee_typslde_subfoot_one}</FindCountry.Title>
          </Wrap>
          {!isMobile && (
            <>
              <Wrap
                sx={{
                  gridArea: "search",
                  display: "grid",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gapY: "2px",
                  position: "relative",
                }}
              >
                <Input
                  fullWidth
                  value={country}
                  startIcon={<SearchIcon />}
                  placeholder={accountsFees.ac_fee_typslde_subfoot_searchtxt}
                  onChange={(e) => handleLocation(e.target.value)}
                  helperText={accountsFees.ac_fee_typslde_subfoot_searchtxthint}
                />

                {country && renderResults()}
                {countryModal && <p>{countryTxt}</p>}
              </Wrap>
            </>
          )}
        </FindCountry.Root>
      </Section>
    </>
  );
};
