import styled from "styled-components";

export const CareersWorkforceDiv = styled.div<{ border: boolean }>`
  display: flex;
  align-items: center;
  padding: 40px 0px 40px 60px;
  border-bottom: ${(props) => (props.border ? "1px solid grey" : "")};
  column-gap: 50px;
`;
export const CareersWorkforceImgDiv = styled.div`
  width: 130px;
  height: 130px;
`;
export const CareersWorkforceImg = styled.img`
  width: 130px;
  height: 130px;
`;
export const CareersWorkforceText = styled.p`
  font-size: 20px;
  text-align: justify;
`;

export const CareersWorkforceBlock = styled.div`
  margin-bottom: 80px;
  @media (max-width: 1400px) {
    margin: 0px auto 80px auto;
  }
`;
export const CareersWorkforceBlockDiv = styled.div`
  display: flex;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;
export const CareersWorkforceBlockMainDiv = styled.div<{
  space: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  padding: ${(props) => (props.space ? "0px 30px 40px 30px" : "50px 30px 0px 30px")};
  border-right: ${(props) => (props.borderRight ? "1px solid grey" : "")};
  border-bottom: ${(props) => (props.borderBottom ? "1px solid grey" : "")};
  @media (max-width: 1400px) {
    border: none;
    padding: 50px 0px 10px 0px;
  }
`;
export const CareersWorkforceBlockImg = styled.img`
  width: 120px;
  height: 120px;
  margin-right: 20px;
  margin-bottom: 20px;
  @media (max-width: 1400px) {
    margin: 0px 30px 0px 0px;
  }
`;
export const CareersWorkforceBlockHeading = styled.h5`
  margin-bottom: 5px;
  font-size: 28px;
`;

export const CareersWorkforceBlockText = styled.p`
  font-size: 20px;
`;

export const ResponseMessage = styled.div`
  font-size: 2.4rem;
  line-height: 3.6rem;
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
`;
