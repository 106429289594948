import React, { useEffect } from "react";
import { useMediaQuery } from "../../../utils/use-media-query";
import { Wrap } from "../../../components/wrap/wrap.component";
import { Person } from "./person.styles";
import { uiAboutUsLeadershipPerson, uiDataSelectedLanguageId } from "../../../redux/uiData/selectors";
import { useDispatch, useSelector } from "../../../redux/store";
import operations from "../../../redux/uiData/operations";
import { useParams } from "react-router-dom";
import { LeadershipPersonData } from "../../../api/types/fetch.ui.types";

export const PersonPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("sm");
  const language = useSelector(uiDataSelectedLanguageId);
  const { id } = useParams();
  const data = useSelector(uiAboutUsLeadershipPerson);

  useEffect(() => {
    if (data === null) dispatch(operations.getAboutUsLeadershipPerson({ languageId: language, linkId: `/${id}/` }));
  }, [data, dispatch, id, language]);

  const person: LeadershipPersonData = data ? data : null;

  return (
    <>
      {person && (
        <>
          <Person.Header $flexDirection={isMobile ? "column-reverse" : "row"} $isMobileHeader={isMobile}>
            <Person.Wrapper $isMobileWrapper={isMobile}>
              <Person.Title $isMobileText={isMobile}>{person?.abtus_ldr_prfle_name}</Person.Title>
              <Person.Subtitle $isMobileText={isMobile}>{person?.abtus_ldr_prfle_position}</Person.Subtitle>
            </Person.Wrapper>

            <Person.ImageWrapper $isMobileWrapper={isMobile}>
              <Wrap
                sx={{
                  background: `url(/assets/images/leadership/${person?.abtus_ldr_prfle_image})`,
                  width: isMobile ? "31rem" : "55rem",
                  height: isMobile ? "21rem" : "38.3rem",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              />
            </Person.ImageWrapper>
          </Person.Header>

          <Person.Root $isMobileRoot={isMobile}>
            <Person.Description
              $isMobileText={isMobile}
              dangerouslySetInnerHTML={{ __html: person?.abtus_ldr_prfle_para_one }}
            />
            <Person.Description
              $isMobileText={isMobile}
              dangerouslySetInnerHTML={{ __html: person?.abtus_ldr_prfle_para_two }}
            />
            <Person.Description
              $isMobileText={isMobile}
              dangerouslySetInnerHTML={{ __html: person?.abtus_ldr_prfle_para_three }}
            />
          </Person.Root>

          <Person.Divider />
        </>
      )}
    </>
  );
};
