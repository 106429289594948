import { SlideSection } from "../../components/silde-section/slide-section.component";
import MissionBg from "../../assets/backgrounds/mission/home_slider.png";
import { Section } from "../../components/section/section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import { uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { IPageContent, TextContent } from "../../components/main-layout/text-content/text-content";
import parse from "html-react-parser";
import { Wrap } from "../../components/wrap/wrap.component";
import {
  KeyMetricsArrowImg,
  KeyMetricsCircleDiv,
  KeyMetricsMainDiv,
  KeyMetricsHeading,
  KeyMetricsHorizontalDiv,
  KeyMetricsImg,
  KeyMetricsContentDiv,
  KeyMetricsMainText,
  KeyMetricsSecondaryText,
  KeyMetricsTableImg,
  KeyMetricsText,
  KeyMetricsTextDiv,
  MissionTabHeading,
  MissionTabMainBlock,
  MissionTabText,
  KeyMetricsDiv,
  MissionTabDiv,
  KeyMetricsYearsDiv,
} from "./mission.styles";
import earth from "../../assets/icons/earth.svg";
import briefCase from "../../assets/icons/brief_case.svg";
import map from "../../assets/icons/map.svg";
import crypro from "../../assets/icons/crypro.svg";
import revenue from "../../assets/icons/revenue.svg";
import employees from "../../assets/icons/employees.svg";
import employeesLight from "../../assets/icons/employees_light.svg";
import dollarLight from "../../assets/icons/dollar_light.svg";
import dollarDark from "../../assets/icons/dollar_dark.svg";
import tickLight from "../../assets/icons/tick_light.svg";
import tickDark from "../../assets/icons/tick_dark.svg";
import arrowLight from "../../assets/icons/arrow_light.svg";
import arrowDark from "../../assets/icons/arrow_dark.svg";
import arrow from "../../assets/icons/arrow.svg";

import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const MissionPage = () => {
  const { aboutUsMission } = useSelector(uiDataWebsiteText);
  const isMobile = useMediaQuery("sm");
  const matrics = [
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadTen,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadTen_txtone,
      align: "left",
      position: "right",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadNine,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadNine_txtone,
      align: "right",
      position: "left",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadEight,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadEight_txtone,
      align: "left",
      position: "right",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadSeven,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadSeven_txtone,
      align: "right",
      position: "left",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadSix,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadSix_txtone,
      align: "left",
      position: "right",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadFive,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadFive_txtone,
      align: "right",
      position: "left",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadFour,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadFour_txtone,
      align: "left",
      position: "right",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadThree,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadThree_txtone,
      align: "right",
      position: "left",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadTwo,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadTwo_txtone,
      align: "left",
      position: "right",
    },
    {
      heading: aboutUsMission.abtus_misn_headThree_ParaHeadOne,
      text: aboutUsMission.abtus_misn_headThree_ParaHeadOne_txtone,
      align: "right",
      position: "left",
    },
  ];
  const textValueOne: IPageContent[] = [
    {
      title: aboutUsMission.abtus_misn_headOne,
      miniTitle: aboutUsMission.abtus_misn_subheadOne,
      article: [
        aboutUsMission.abtus_misn_subheadOne_ParaOne +
          " " +
          aboutUsMission.abtus_misn__subheadOne_ParaTwo +
          " " +
          aboutUsMission.abtus_misn__subheadOne_ParaThree +
          " " +
          aboutUsMission.abtus_misn__subheadOne_ParaFour,
      ],
    },
    {
      title: aboutUsMission.abtus_misn_headTwo,
      article: [
        aboutUsMission.abtus_misn_headTwo_ParaOne +
          " " +
          aboutUsMission.abtus_misn_headTwo_ParaTwo +
          " " +
          aboutUsMission.abtus_misn_headTwo_ParaThree +
          " " +
          aboutUsMission.abtus_misn_headTwo_ParaFour +
          " " +
          aboutUsMission.abtus_misn_headTwo_ParaFive +
          "" +
          aboutUsMission.abtus_misn_headTwo_ParaSix +
          " " +
          aboutUsMission.abtus_misn_headTwo_ParaSeven,
      ],
    },
    {
      title: aboutUsMission.abtus_misn_headThree,
    },
    {
      title: aboutUsMission.abtus_misn_headThreeSub,
    },
  ];
  const textValueTwo: IPageContent[] = [
    {
      title: aboutUsMission.abtus_misn_headFour,

      titledList: [
        {
          upperSubtitle: aboutUsMission.abtus_misn_subheadFour_One,
          article: [aboutUsMission.abtus_misn_subheadFour_One_txtone],
        },
        {
          upperSubtitle: aboutUsMission.abtus_misn_subheadFour_Two,
          article: [
            aboutUsMission.abtus_misn_subheadFour_Two_txtone,
            aboutUsMission.abtus_misn_subheadFour_Two_txttwo,
            aboutUsMission.abtus_misn_subheadFour_Four_txtthree,
          ],
        },
        {
          article: [aboutUsMission.abtus_misn_subheadFour_Five_txtfour_sub],
          list: [
            aboutUsMission.abtus_misn_subheadFour_Five_txtfour_artone,
            aboutUsMission.abtus_misn_subheadFour_Five_txtfour_arttwo,
            aboutUsMission.abtus_misn_subheadFour_Five_txtfour_artthree,
            aboutUsMission.abtus_misn_subheadFour_Five_txtfour_artfour,
            aboutUsMission.abtus_misn_subheadFour_Five_txtfour_artfive,
            aboutUsMission.abtus_misn_subheadFour_Five_txtfour_artsix,
            aboutUsMission.abtus_misn_subheadFour_Five_txtfour_artseven,
            aboutUsMission.abtus_misn_subheadFour_Five_txtfour_arteight,
          ],
        },
      ],
    },
    { article: [aboutUsMission.abtus_misn_headFour_ParaOne, aboutUsMission.abtus_misn_headFour_ParaTwo] },
    { article: [aboutUsMission.abtus_misn_subheadFour_Six_txtfive] },
    {
      title: aboutUsMission.abtus_misn_headFive,
    },
  ];
  return (
    <>
      <HelmetContainer metaData={aboutUsMission.metaData} />
      <SlideSection
        bgImage={MissionBg}
        title={parse(aboutUsMission.abtus_misn_mainhead + " <br> " + aboutUsMission.abtus_misn_headOne)}
        bgVariant={"gradient"}
        justify={"center"}
        titleAlign={"center"}
        titleWidth={"100%"}
        BgImageStyles={
          isMobile
            ? {
                backgroundRepeat: "no-repeat",
                backgroundPosition: "60%",
              }
            : {
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
              }
        }
      />
      <Section mainContent m={"9rem auto 19rem"}>
        <TextContent content={textValueOne} />
        {/*  */}
        <KeyMetricsDiv>
          <KeyMetricsMainDiv>
            <KeyMetricsContentDiv borderRight={true} borderBottom={true}>
              <Wrap sx={{ marginLeft: "30px", textAlign: "center" }}>
                <KeyMetricsMainText>{aboutUsMission.abtus_misn_headThree_ColOne_RowOne}</KeyMetricsMainText>
                <KeyMetricsSecondaryText>
                  {aboutUsMission.abtus_misn_headThree_ColOne_RowOne_nxt}
                </KeyMetricsSecondaryText>
              </Wrap>
              <KeyMetricsTableImg src={earth} alt="" />
            </KeyMetricsContentDiv>
            <KeyMetricsContentDiv borderRight={true} borderBottom={true}>
              <Wrap sx={{ marginRight: "40px", textAlign: "center" }}>
                <KeyMetricsMainText>{aboutUsMission.abtus_misn_headThree_ColOne_RowTwo}</KeyMetricsMainText>
                <KeyMetricsSecondaryText>
                  {aboutUsMission.abtus_misn_headThree_ColOne_RowTwo_nxt}
                </KeyMetricsSecondaryText>
              </Wrap>
              <KeyMetricsTableImg src={briefCase} alt="" />
            </KeyMetricsContentDiv>
            <KeyMetricsContentDiv borderBottom={true}>
              <Wrap sx={{ marginRight: "40px" }}>
                <KeyMetricsTableImg src={map} alt="" />
              </Wrap>
              <Wrap sx={{ textAlign: "center" }}>
                <KeyMetricsMainText>{aboutUsMission.abtus_misn_headThree_ColOne_RowThree}</KeyMetricsMainText>
                <KeyMetricsSecondaryText>
                  {aboutUsMission.abtus_misn_headThree_ColOne_RowThree_nxt}
                </KeyMetricsSecondaryText>
              </Wrap>
            </KeyMetricsContentDiv>
          </KeyMetricsMainDiv>
          {/* ------------ second row ----------- */}
          <KeyMetricsMainDiv>
            <KeyMetricsContentDiv borderRight={true}>
              <Wrap sx={{ marginRight: "40px" }}>
                <KeyMetricsTableImg src={crypro} alt="" />
              </Wrap>
              <Wrap sx={{ textAlign: "center" }}>
                <KeyMetricsMainText>{aboutUsMission.abtus_misn_headThree_ColTwo_RowOne}</KeyMetricsMainText>
                <KeyMetricsSecondaryText>
                  {aboutUsMission.abtus_misn_headThree_ColTwo_RowOne_nxt}
                </KeyMetricsSecondaryText>
              </Wrap>
            </KeyMetricsContentDiv>
            <KeyMetricsContentDiv borderRight={true}>
              <Wrap sx={{ marginRight: "40px" }}>
                <KeyMetricsTableImg src={revenue} alt="" />
              </Wrap>
              <Wrap sx={{ textAlign: "center" }}>
                <KeyMetricsMainText>{aboutUsMission.abtus_misn_headThree_ColTwo_RowTwo}</KeyMetricsMainText>
                <KeyMetricsSecondaryText>
                  {aboutUsMission.abtus_misn_headThree_ColTwo_RowTwo_nxt}
                </KeyMetricsSecondaryText>
              </Wrap>
            </KeyMetricsContentDiv>
            <KeyMetricsContentDiv>
              <Wrap sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {[...Array(42)].map((item, index) => {
                  return (
                    <>
                      {<KeyMetricsImg src={employees} alt="" width={24} height={24} />}
                      {index + 1 === 42 && (
                        <Wrap sx={{ textAlign: "center", display: "flex", columnGap: "10px", alignItems: "center" }}>
                          <KeyMetricsMainText>{aboutUsMission.abtus_misn_headThree_ColTwo_RowThree}</KeyMetricsMainText>
                          <KeyMetricsSecondaryText>
                            {aboutUsMission.abtus_misn_headThree_ColTwo_RowThree_nxt}
                          </KeyMetricsSecondaryText>
                        </Wrap>
                      )}
                    </>
                  );
                })}
              </Wrap>
            </KeyMetricsContentDiv>
          </KeyMetricsMainDiv>
        </KeyMetricsDiv>
        {/*  */}
        <KeyMetricsYearsDiv>
          {matrics.map((matrics, index) => {
            return (
              <Wrap sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <KeyMetricsTextDiv align={matrics.align} position={matrics.position}>
                  <KeyMetricsHeading>{matrics.heading}</KeyMetricsHeading>
                  <KeyMetricsText>{matrics.text}</KeyMetricsText>
                </KeyMetricsTextDiv>
                <KeyMetricsCircleDiv />
                {index + 1 !== 10 && <KeyMetricsHorizontalDiv />}
              </Wrap>
            );
          })}
        </KeyMetricsYearsDiv>
        {/*  */}
        <TextContent content={textValueTwo} />
        <MissionTabDiv>
          <MissionTabMainBlock borderRight={true} borderBottom={true}>
            <MissionTabHeading>{aboutUsMission.abtus_misn_headFive_ParaHeadOne}</MissionTabHeading>
            <Wrap sx={{ display: "flex", justifyContent: "center" }}>
              <Wrap sx={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "195px" }}>
                {[...Array(25)].map((item, index) => {
                  return (
                    <>
                      {(index + 1) % 5 === 0 ? (
                        <KeyMetricsImg src={employees} alt="" width={30} height={30} />
                      ) : (
                        <KeyMetricsImg src={employeesLight} alt="" width={30} height={30} />
                      )}
                    </>
                  );
                })}
              </Wrap>
              <KeyMetricsArrowImg src={arrow} alt="" />
              <Wrap sx={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "195px" }}>
                {[...Array(25)].map(() => {
                  return <KeyMetricsImg src={employeesLight} alt="" width={30} height={30} />;
                })}
              </Wrap>
            </Wrap>
            <MissionTabText>{aboutUsMission.abtus_misn_headFive_ParaHeadOne_txtone}</MissionTabText>
          </MissionTabMainBlock>
          <MissionTabMainBlock borderBottom={true}>
            <MissionTabHeading>{aboutUsMission.abtus_misn_headFive_ParaHeadTwo}</MissionTabHeading>
            <Wrap sx={{ display: "flex", justifyContent: "center" }}>
              <Wrap sx={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "195px" }}>
                {[...Array(25)].map((item, index) => {
                  return (
                    <>
                      {(index + 1) % 5 === 0 && index + 1 !== 5 ? (
                        <KeyMetricsImg src={dollarDark} alt="" width={30} height={30} />
                      ) : (
                        <KeyMetricsImg src={dollarLight} alt="" width={30} height={30} />
                      )}
                    </>
                  );
                })}
              </Wrap>
              <KeyMetricsArrowImg src={arrow} alt="" />
              <Wrap sx={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "195px" }}>
                {[...Array(25)].map(() => {
                  return <>{<KeyMetricsImg src={dollarLight} alt="" width={30} height={30} />}</>;
                })}
              </Wrap>
            </Wrap>
            <MissionTabText>{aboutUsMission.abtus_misn_headFive_ParaHeadTwo_txtone}</MissionTabText>
          </MissionTabMainBlock>
        </MissionTabDiv>
        <MissionTabDiv>
          <MissionTabMainBlock borderRight={true}>
            <MissionTabHeading>{aboutUsMission.abtus_misn_headFive_ParaHeadThree}</MissionTabHeading>
            <Wrap sx={{ display: "flex", justifyContent: "center" }}>
              <Wrap
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "10px",
                  width: "195px",
                  height: "190px",
                }}
              >
                {[...Array(25)].map((item, index) => {
                  return (
                    <>
                      {index + 1 > 13 ? (
                        <KeyMetricsImg src={tickDark} alt="" width={30} height={30} />
                      ) : (
                        <KeyMetricsImg src={tickLight} alt="" width={30} height={30} />
                      )}
                    </>
                  );
                })}
              </Wrap>
              <KeyMetricsArrowImg src={arrow} alt="" />
              <Wrap sx={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "195px" }}>
                {[...Array(25)].map(() => {
                  return <>{<KeyMetricsImg src={tickLight} alt="" width={30} height={30} />}</>;
                })}
              </Wrap>
            </Wrap>
            <MissionTabText>{aboutUsMission.abtus_misn_headFive_ParaHeadThree_txtone}</MissionTabText>
          </MissionTabMainBlock>
          <MissionTabMainBlock>
            <MissionTabHeading>{aboutUsMission.abtus_misn_headFive_ParaHeadFour}</MissionTabHeading>
            <Wrap sx={{ display: "flex", justifyContent: "center" }}>
              <Wrap
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "10px",
                  width: "195px",
                  height: "190px",
                }}
              >
                {[...Array(25)].map((item, index) => {
                  return (
                    <>
                      {index + 1 > 14 ? (
                        <KeyMetricsImg src={arrowDark} alt="" width={30} height={30} />
                      ) : (
                        <KeyMetricsImg src={arrowLight} alt="" width={30} height={30} />
                      )}
                    </>
                  );
                })}
              </Wrap>
              <KeyMetricsArrowImg src={arrow} alt="" />
              <Wrap sx={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "195px" }}>
                {[...Array(25)].map((item, index) => {
                  return (
                    <>
                      {index + 1 === 25 ? (
                        <KeyMetricsImg src={arrowDark} alt="" width={30} height={30} />
                      ) : (
                        <KeyMetricsImg src={arrowLight} alt="" width={30} height={30} />
                      )}
                    </>
                  );
                })}
              </Wrap>
            </Wrap>
            <MissionTabText>{aboutUsMission.abtus_misn_headFive_ParaHeadFour_txtone}</MissionTabText>
          </MissionTabMainBlock>
        </MissionTabDiv>
      </Section>
    </>
  );
};
