import React, { useEffect, useState } from "react";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import MainBg from "../../assets/backgrounds/financialStatements/financialStatements_main_bg.png";
import { Section } from "../../components/section/section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import {
  uiAboutUsFinanStats,
  uiDataSelectedLanguageId,
  uiDataWebsiteText,
  uiAboutUsFinanDropDown,
} from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { Select } from "../../components/inputs/select/select.component";
import { Wrap } from "../../components/wrap/wrap.component";
import { SelectHandler } from "../../components/inputs/select/select.types";
import Modal from "react-modal";
import { Input } from "../../components/inputs/input/input.component";
import { ButtonArrow } from "../../components/button-arrow/button-arrow.component";
import { fetchAboutUsFsShLogin } from "../../api/services/fetch.ui";
import { Base64 } from "js-base64";
import { FinancialStatementMapItems, FinancialStatementText } from "./financial-statements.styles";

import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";
import { ContactForm } from "../contact/contact.styles";

export const FinancialStatementsPage: React.FC = (): JSX.Element => {
  const { financialStatements } = useSelector(uiDataWebsiteText);
  const language = useSelector(uiDataSelectedLanguageId);
  const aboutUsFinanStats = useSelector(uiAboutUsFinanStats);
  const aboutUsFinanDropDown = useSelector(uiAboutUsFinanDropDown);
  const isMobile = useMediaQuery("sm");
  let [dummyData, setDummyData] = useState(null);
  let [dummyYearData, setDummyYearData] = useState(null);
  const [formData, setFormData] = useState({
    Filings: { id: "1", value: "All Filings", label: "All Filings" },
    year: { id: "0", value: "All", label: "All" },
  });
  const [modalIsOpen, setIsOpen] = useState<any>(false);
  const [response, setResponse] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {
    const { data } = await fetchAboutUsFsShLogin({
      languageId: language,
      userName: name,
      password: password,
      finDataId: modalIsOpen.finDataId,
      doc_type: modalIsOpen.docType,
      filing: modalIsOpen.filing,
    });
    //@ts-ignore
    setResponse(Base64.decode((data as { status_msg: string }).status_msg));
    if (data) {
      window.localStorage.setItem("name", name);
      window.localStorage.setItem("password", password);
      const now = new Date().getTime();
      const expirationTime = now + 5 * 60 * 1000;
      window.localStorage.setItem('expiry', JSON.stringify(expirationTime));
      window.open(Base64.decode(data.data[0].doc_link), "_blank");
      closeModal();
    }
  };

  const openModal = async (props: any) => {
    const expiry = JSON.parse(window.localStorage.getItem('expiry'));
    if (expiry) {
      const now = new Date().getTime();
      if (now > expiry) {
        window.localStorage.removeItem('name');
        window.localStorage.removeItem('password');
      }
    }
    const name = window.localStorage.getItem("name");
    const password = window.localStorage.getItem("password");
    if (name && password) {
      const { data } = await fetchAboutUsFsShLogin({
        languageId: language,
        finDataId: props.finDataId,
        userName: name,
        password: password,
        doc_type: props.docType,
        filing: props.filing,
      });
      //@ts-ignore
      if (data?.status) {
        window.open(Base64.decode(data.data[0].doc_link), "_blank");
      } else {
        window.localStorage.removeItem("name");
        window.localStorage.removeItem("password");
        setIsOpen(props);
        document.body.style.overflow = "hidden";
      }
    } else {
      setIsOpen(props);
      document.body.style.overflow = "hidden";
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setName("");
    setPassword("");
    setResponse('');
    document.body.style.overflow = "unset";
  };

  const handleSelect: SelectHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    if (aboutUsFinanDropDown?.filings_drop_data?.length > 0) {
      setDummyData([
        ...aboutUsFinanDropDown?.filings_drop_data.map((item: any, index: any) => {
          return { id: `${index + 1}`, value: item, label: item };
        }),
      ]);
    }

    if (aboutUsFinanDropDown?.year_drop_data?.length > 0) {
      setDummyYearData([
        ...aboutUsFinanDropDown?.year_drop_data.map((item: any, index: any) => {
          return { id: `${index + 1}`, value: item, label: item };
        }),
      ]);
    }
  }, [aboutUsFinanDropDown]);

  useEffect(() => {
    if (dummyData && dummyYearData) {
      setFormData({ Filings: dummyData[0], year: dummyYearData[0] });
    }
  }, [dummyData, dummyYearData]);
  return (
    <>
      <HelmetContainer metaData={financialStatements.metaData} />
      <SlideSection
        bgImage={MainBg}
        title={financialStatements.abtus_finst_mainhead}
        bgVariant={"gradient"}
        titleAlign={"center"}
        justify={"center"}
        titleWidth={"500px"}
        BgImageStyles={isMobile ? { backgroundSize: "cover", backgroundPosition: "40%" } : { backgroundSize: "cover" }}
      />

      <Section
        mainContent
        m={isMobile ? "0rem auto 20rem" : "0 auto"}
        style={{ overflow: "hidden", marginBottom: "15rem" }}
      >
        {dummyData?.length && dummyYearData?.length && (
          <>
            <Wrap sx={{ width: "100%", display: "flex" }}>
              <Wrap sx={{ marginRight: "9rem", width: "100%", height: "25rem" }}>
                <Select
                  options={dummyData}
                  value={formData.Filings}
                  onSelect={handleSelect}
                  optionsPosition={"bottom"}
                  fullWidth
                  paddingFull
                  noAdornment
                  disabled={true}
                  InputProps={{
                    InputNativeProps: { style: { height: "9.4rem" } },
                  }}
                  name={"Filings"}
                ></Select>
              </Wrap>
              <Wrap sx={{ width: "100%", height: "0px" }}>
                <Select
                  options={dummyYearData}
                  value={formData.year}
                  onSelect={handleSelect}
                  optionsPosition={"bottom"}
                  fullWidth
                  paddingFull
                  noAdornment
                  disabled={true}
                  InputProps={{
                    InputNativeProps: { style: { height: "9.4rem" } },
                  }}
                  name={"year"}
                ></Select>
              </Wrap>
            </Wrap>
            <Wrap
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: " 25% 25% 25% 25%",
                textAlign: "center",
                marginBottom: "3rem",
              }}
            >
              <h3>{financialStatements.abtus_finst_tbl_hdone}</h3>
              <h3>{financialStatements.abtus_finst_tbl_hdtwo}</h3>
              <h3>{financialStatements.abtus_finst_tbl_hdthree}</h3>
              <h3>{financialStatements.abtus_finst_tbl_hdfour}</h3>
            </Wrap>
          </>
        )}
        <FinancialStatementMapItems>
          {aboutUsFinanStats
            ?.filter((el: any) => {
              if (formData.year.value !== "All") {
                const itemDate = el.abtus_finst_report_colthree?.split(' ')
                return itemDate[itemDate.length - 1] === formData.year.value;
              } else return true;
            })
            ?.filter((el: any) => {
              if (formData.Filings.value !== "All Filings") {
                return el.abtus_finst_report_coltwo.includes(formData.Filings.value.split("")[0]);
              } else return true;
            })
            .map((el: any, idx: any) => {
              return (
                <Wrap
                  key={"stats" + idx}
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: " 25% 25% 25% 25%",
                    textAlign: "center",
                    key: idx + "list",
                    borderBottom: "0.1rem solid white",
                    borderTop: "0.1rem solid white",
                    padding: "1rem",
                    fontSize: "22px",
                  }}
                >
                  <FinancialStatementText>{el.abtus_finst_report_colone}</FinancialStatementText>
                  <FinancialStatementText>{el.abtus_finst_report_coltwo}</FinancialStatementText>
                  <FinancialStatementText>{el.abtus_finst_report_colthree}</FinancialStatementText>
                  <Wrap sx={{ textDecoration: "underline", color: "white", display: "flex", justifyContent: "center" }}>
                    <p
                      onClick={() => {
                        openModal({
                          docType: el.abtus_finst_report_colfour,
                          filing: el.abtus_finst_report_colone,
                          finDataId: el.abtus_findata_id,
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {el.abtus_finst_report_colfour}{" "}
                    </p>
                    <p
                      onClick={() => {
                        openModal({
                          docType: el.abtus_finst_report_colfour_rtf,
                          filing: el.abtus_finst_report_colone,
                          finDataId: el.abtus_findata_id,
                        });
                      }}
                      style={{ margin: "0px 15px", cursor: "pointer" }}
                    >
                      {el.abtus_finst_report_colfour_rtf}
                    </p>
                    <p
                      onClick={() => {
                        openModal({
                          docType: el.abtus_finst_report_colfour_xls,
                          filing: el.abtus_finst_report_colone,
                          finDataId: el.abtus_findata_id,
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {el.abtus_finst_report_colfour_xls}
                    </p>
                  </Wrap>
                </Wrap>
              );
            })}
        </FinancialStatementMapItems>
      </Section>
      <Modal
        onRequestClose={closeModal}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        className="Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: "500",
          },
          content: {
            display: "flex",
            outline: "none",
            width: "40%",
            margin: "auto",
            fontFamily: "inherit",
            borderRadius: "150px",
          },
        }}
      >
        <Wrap
          sx={{
            height: "400px",
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "3rem",
            color: "#fff",
          }}
        >
          <p style={{ width: "88%", fontSize: "18px", textAlign: "center" }}>
            {financialStatements.abtus_finst_model_title}
          </p>
          <Wrap sx={{ width: "70%", marginTop: "1rem" }}>
            <p>{financialStatements.abtus_finst_model_userfield}</p>
            <Input
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={financialStatements.abtus_finst_model_user_placeholder}
            />
          </Wrap>
          <Wrap sx={{ width: "70%", marginTop: "1rem" }}>
            <p>{financialStatements.abtus_finst_model_passwordfield}</p>
            <Input
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={financialStatements.abtus_finst_model_password_placeholder}
              type={"password"}
            />
          </Wrap>
          {response ?? <ContactForm.Support.Title>{response}</ContactForm.Support.Title>}
          <Wrap
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "3.2rem",
            }}
          >
            <ButtonArrow onClick={handleSubmit}>Login</ButtonArrow>
          </Wrap>
        </Wrap>
      </Modal>
    </>
  );
};
