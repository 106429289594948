import styled from "styled-components";

export const BlockScheme = styled.div`
  margin: 5rem auto;
  text-align: center;
  display : flex;
  justify-content : center;
  flex-direction : column;
  width: 90%;
  align-items: center;
  position: relative

  ul {
    display:inline-block;
    position: relative;
    text-align: center;
    width: 90%;
  }

  li {
    position: relative;
    display: table-cell;
    padding: 7rem 0;
    vertical-align: top;
  }
  /* _________ */
  li:before {
    outline: solid 1px #262626;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    
  }
  li:first-child:before {
    left: 50%;
  }
  li:last-child:before {
    right: 50%;
  }

  code {
    display: flex;
    position: relative;
    color: #d3d3d3;
    width: 25rem;
    height: 11rem;
    align-items: center;
    margin: 0 2rem;
    font-size: 20px;
    line-height: 2rem;
    font-family: "Open sans";
    border: 1px solid #2E2E2E;
    text-align: center;
    justify-content: center;
    border-radius: 5px;
  }
  span {
    display: flex;
    justify-content: center;
    position: relative;
    background-color: #383838;
    color: white;
    width: 26rem;
    height: 9rem;
    align-items: center;
    margin: 7rem auto;
    font-size: 2.5rem;
    font-family: "Open sans";
    line-height: 3.4rem;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    
  }

  /* | */
  span:after{
    outline: solid 1px #262626;
    content: "";
    height: 7rem;
    left: 50%;
    position: absolute;
    top: 100%;


  }
  code:before {
    outline: solid 1px #262626;
    content: "";
    height: 7rem;
    left: 50%;
    position: absolute;


  }
  span:after {
    
    bottom:74.023%;
  }
  code:before {
    
    top: -7rem;
  }
`;
