import styled from "styled-components";

export const ErrorWrapper = styled.div`
  background-color: #fff;
`;
export const ErrorContent = styled.div`
  max-width: 980px;
  margin: 0px auto;
  padding: 15px;
  min-height: 150px;
  text-align: center;
  padding-bottom: 100px;
`;

export const ErrorTitle = styled.h1`
  text-align: center;
  font-size: 48px;
  font-weight: 500;
  color: #8f8f8f;
  margin-bottom: 30px;
  padding-top: 55px;
  line-height: 1.1;
`;

export const Errortext = styled.p`
  font-size: 16px;
  max-width: 900px;
  display: inline-block;
  line-height: 32px;
  font-weight: 300;
  color: #333333;
`;

export const HomeLink = styled.div`
    display: inline-block;
    background-color: #6c6c6c;
    border-color: #7FBA00;
    margin: 50px 0px 25px 0px;
    padding: 10px 15px;
    border-radius: 3px;
    text-decoration: none !important;
    cursor:pointer;
}
`;

export const HomeText = styled.p`
    color: #FFF !important;
    text-decoration: none !important;
    font-size: 22px;
}
`;
export const AlternateLinks = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
}
`;

export const AlternateLinkText = styled.p`
    padding: 0px 9px;
    font-size: 16px;
    &:hover {
    text-decoration: underline;
  }
}
`;
