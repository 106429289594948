import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import MainBg from "../../assets/backgrounds/media-coverage/media_coverage_main_bg.png";
import { Section } from "../../components/section/section.component";
import { News } from "./investor_news.styles";
import { Divider } from "../../components/divider/divider.styles";
import { Wrap } from "../../components/wrap/wrap.component";
import { Input } from "../../components/inputs/input/input.component";
import { ButtonArrow } from "../../components/button-arrow/button-arrow.component";
import FormBg from "../../assets/backgrounds/lines_bg.png";
import { useMediaQuery } from "../../utils/use-media-query";
import { useNavigate } from "react-router-dom";
import { ArticleItem } from "./investor_news.types";
import { previewData } from "../../mock-data/media-coverage";
import { uiDataWebsiteText, uiMediaCenterInvestorNews } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import shareIcon from "../../assets/icons/share_icon.svg";
import twitterLogo from "../../assets/icons/twitter_icon.svg";
import linkdinLogo from "../../assets/icons/linkdin_logo.svg";

import parse from "html-react-parser";
import { Helmet } from "react-helmet";

import { Loader } from "../../components/loader/loader.component";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

const sizesMap = new Map<number, ArticleItem["size"]>([
  [0, "big"],
  [1, "small-top"],
  [2, "small-bottom"],
]);

const sizesMapReverse = new Map<number, ArticleItem["size"]>([
  [0, "small-top"],
  [1, "small-bottom"],
  [2, "big"],
]);

export const InvestorNewsPage: React.FC = (): JSX.Element => {
  const [showLinks, setShowLinks] = useState(null);
  const { mediaCenterInvestorNews } = useSelector(uiDataWebsiteText);
  const mediaCenterInvestorNewsContent = useSelector(uiMediaCenterInvestorNews);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState<{ name: string; email: string }>({
    name: "",
    email: "",
  });

  const nav = useNavigate();

  const isMobile = useMediaQuery("sm");

  const getSizeMap = (index: number): Map<number, ArticleItem["size"]> => {
    return index % 2 === 0 ? sizesMap : sizesMapReverse;
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    console.log(formData);
  };

  const handleClickItem =
    (link: string, type: number): MouseEventHandler<HTMLDivElement> =>
      () => {
        let prInvestorNewsLink = link;
        if (type) {
          prInvestorNewsLink = `/PressReleaseDocs/${link.split('/')[1]}.pdf`;
        }
        window.open(prInvestorNewsLink, "_blank");
      };

  useEffect(() => {
    if (!mediaCenterInvestorNewsContent) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [mediaCenterInvestorNewsContent]);
  return (
    <>
      <HelmetContainer metaData={mediaCenterInvestorNews.metaData} />
      <SlideSection
        bgImage={MainBg}
        title={mediaCenterInvestorNews.mc_invnws_headone_nxt + " " + mediaCenterInvestorNews.mc_invnws_headone}
        titleAlign="center"
        titleWidth={"550px"}
        justify="center"
        m={"0 auto 8rem"}
        BgImageStyles={
          isMobile
            ? {
              backgroundPosition: "40%",
              backgroundSize: "180%",
              opacity: "0.8",
            }
            : {
              backgroundPosition: "50%",
              backgroundSize: "70rem",
            }
        }
      />
      <Section mainContent>
        {loader ? (
          <Loader />
        ) : (
          <>
            <News.Root>
              {mediaCenterInvestorNewsContent?.map((article: any, articleIndex: any) => {
                const { Root, Title, Date, Description } = News.Articles.Item;
                return (
                  <News.Share.ShareMainDiv>
                    <News.Share.ShareLeftDiv onClick={handleClickItem(article.link, +article.type)}>
                      <Date>{article.date}</Date>
                      <Title>{article.title}</Title>
                      <Wrap>
                        <Root
                          size={sizesMap.get(articleIndex)}
                          imgSrc={article.img}
                          key={`news-article-${articleIndex}`}
                        />
                        <Description>{article.description}</Description>
                      </Wrap>
                      <News.Share.MobileShareLinkDiv>
                        <img
                          onClick={() => window.open(article.linkedin, "_blank")}
                          style={{ margin: "30px 0px 0px 0px" }}
                          src={linkdinLogo}
                          alt=""
                          width={"26px"}
                        />
                        <img
                          onClick={() => window.open(article.twitter, "_blank")}
                          style={{ margin: "30px 0px 0px 30px" }}
                          src={twitterLogo}
                          alt=""
                          width={"25px"}
                        />
                      </News.Share.MobileShareLinkDiv>
                      <News.Share.ShareDivider></News.Share.ShareDivider>
                    </News.Share.ShareLeftDiv>

                    <News.Share.ShareDiv>
                      <News.Share.ShareTextDiv
                        onMouseEnter={() => setShowLinks(articleIndex)}
                        onMouseLeave={() => setShowLinks(null)}
                      >
                        <News.Share.ShareImg>
                          <img src={shareIcon} alt="" width={"23px"} />
                        </News.Share.ShareImg>
                        Share this post
                        {showLinks === articleIndex && (
                          <News.Share.ShareLinkDiv onMouseEnter={() => setShowLinks(articleIndex)}>
                            <News.Share.ShareLinkedinDiv onClick={() => window.open(article.linkedin, "_blank")}>
                              <img style={{ margin: "0px 20px" }} src={linkdinLogo} alt="" width={"26px"} />
                              <News.Share.ShareLinksText>Share on Linkedin</News.Share.ShareLinksText>
                            </News.Share.ShareLinkedinDiv>
                            <News.Share.ShareTwitterDiv onClick={() => window.open(article.twitter, "_blank")}>
                              <img style={{ margin: "0px 20px 0px 21px" }} src={twitterLogo} alt="" width={"25px"} />
                              <News.Share.ShareLinksText>Share on Twitter</News.Share.ShareLinksText>
                            </News.Share.ShareTwitterDiv>
                          </News.Share.ShareLinkDiv>
                        )}
                      </News.Share.ShareTextDiv>
                    </News.Share.ShareDiv>
                  </News.Share.ShareMainDiv>
                );
              })}
            </News.Root>
          </>
        )}
      </Section>
    </>
  );
};
