import styled from "styled-components";

export const RManagementTitle = styled.h1`
    color :white;
    font-size: 40px;
    font-weight : 600;
    margin-bottom: 64px;
    
`;

export const RManagementHeading = styled.p`
    color:white;
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
`
export const RManagementContent = styled.p`
    color : #A1A1A1;
    margin-bottom : 4rem;
    font-weight: 300;
    text-align: justify;
`;

export const RManagementList = styled.ul`
    margin-bottom : 4rem;
`;

export const RManagementListContent = styled.li`
    color : #A1A1A1;
    font-weight: 300;
    text-align: justify;
    list-style-type: circle;
`;