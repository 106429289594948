import { SlideSection } from "../../components/silde-section/slide-section.component";
import MissionBg from "../../assets/backgrounds/mission/home_slider.png";
import { Section } from "../../components/section/section.component";
import {
  SustainabilitySecondText,
  SustainabilitySecondTextHeading,
  SustainabilitySecondTextImg,
  SustainabilitySecondTextMainDiv,
  SustainabilitySecondTextSecondaryDiv,
  SustainabilityTabBorderDiv,
  SustainabilityTabHeading,
  SustainabilityTabMainDiv,
  SustainabilityTabPerformance,
  SustainabilityTabProgress,
  SustainabilityTabSecondaryDiv,
  SustainabilityTabText,
} from "./sustainability.styles";
import { useMediaQuery } from "../../utils/use-media-query";
import { uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { Wrap } from "../../components/wrap/wrap.component";
import { IPageContent, TextContent } from "../../components/main-layout/text-content/text-content";
import parse from "html-react-parser";
import homeIcon from "../../assets/icons/home_icon.svg";
import goDigital from "../../assets/icons/go_digital.svg";
import world from "../../assets/icons/world.svg";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const SustainabilityPage = () => {
  const { sustainabilityCareers } = useSelector(uiDataWebsiteText);
  const isMobile = useMediaQuery("sm");
  const performance = [
    {
      text: sustainabilityCareers.abtus_sust_headthree_tbl_colmidone_txtone,
      percentage: sustainabilityCareers.abtus_sust_headthree_tbl_colrightone_txtone,
      progress: 92,
    },
    {
      text: sustainabilityCareers.abtus_sust_headthree_tbl_colmidone_txttwo,
      percentage: sustainabilityCareers.abtus_sust_headthree_tbl_colrightone_txttwo,
      progress: 91,
    },
    {
      text: sustainabilityCareers.abtus_sust_headthree_tbl_colmidone_txtthree,
      percentage: sustainabilityCareers.abtus_sust_headthree_tbl_colrightone_txtthree,
      progress: 91,
    },
    {
      text: sustainabilityCareers.abtus_sust_headthree_tbl_colmidone_txtfour,
      percentage: sustainabilityCareers.abtus_sust_headthree_tbl_colrightone_txtfour,
      progress: 75,
    },
    {
      text: sustainabilityCareers.abtus_sust_headthree_tbl_colmidone_txtfive,
      percentage: sustainabilityCareers.abtus_sust_headthree_tbl_colrightone_txtfive,
      progress: 75,
    },
  ];
  const upperTextValue: IPageContent[] = [
    {
      titledList: [
        {
          article: [sustainabilityCareers.abtus_sust_headone_paraone],
        },
      ],
      article: [sustainabilityCareers.abtus_sust_headone_paratwo],
    },
  ];
  const upperSecondTextValue: IPageContent[] = [
    {
      article: [
        sustainabilityCareers.abtus_sust_headone_parathree +
          " " +
          sustainabilityCareers.abtus_sust_headone_parafour +
          " " +
          sustainabilityCareers.abtus_sust_headone_parafive +
          " " +
          sustainabilityCareers.abtus_sust_headone_parasix,
      ],
    },
    {
      title: sustainabilityCareers.abtus_sust_headtwo,
      article: [
        sustainabilityCareers.abtus_sust_headtwo_paraone +
          " " +
          sustainabilityCareers.abtus_sust_headtwo_paratwo +
          sustainabilityCareers.abtus_sust_headtwo_parathree +
          sustainabilityCareers.abtus_sust_headtwo_parafour,
      ],
    },
  ];
  const middleTextValue: IPageContent[] = [
    {
      title: sustainabilityCareers.abtus_sust_headthree,
      article: [
        sustainabilityCareers.abtus_sust_headthree_subheadone_paraone +
          sustainabilityCareers.abtus_sust_headthree_subheadone_paratwo,
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headthree_subheadone_next,
          article: [sustainabilityCareers.abtus_sust_headthree_subheadone_paraone_next],
        },
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headthree_subheadtwo,
          article: [
            sustainabilityCareers.abtus_sust_headthree_subheadtwo_paraone +
              sustainabilityCareers.abtus_sust_headthree_subheadtwo_paratwo +
              sustainabilityCareers.abtus_sust_headthree_subheadtwo_parathree,
          ],
        },
      ],
    },
  ];
  const bottomTextValue: IPageContent[] = [
    {
      miniTitle: sustainabilityCareers.abtus_sust_headfour,
      article: [sustainabilityCareers.abtus_sust_headfour_paraone],
    },
    {
      titledList: [
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadone,
          article: [sustainabilityCareers.abtus_sust_headfour_subheadone_paraone],
        },
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadtwo,
          article: [sustainabilityCareers.abtus_sust_headfour_subheadtwo_paraone],
        },
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadthree,
          article: [sustainabilityCareers.abtus_sust_headfour_subheadthree_paraone],
        },
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadfour,
          article: [sustainabilityCareers.abtus_sust_headfour_subheadfour_paraone],
        },
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadfive,
          article: [sustainabilityCareers.abtus_sust_headfour_subheadfive_paraone],
        },
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadsix,
          article: [sustainabilityCareers.abtus_sust_headfour_subheadsix_paraone],
        },
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadseven,
          article: [sustainabilityCareers.abtus_sust_headfour_subheadseven_paraone],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadeight,
          article: [
            sustainabilityCareers.abtus_sust_headfour_subheadeight_paraone +
              " " +
              sustainabilityCareers.abtus_sust_headfour_subheadeight_paratwo,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: sustainabilityCareers.abtus_sust_headfour_subheadnine,
          article: [sustainabilityCareers.abtus_sust_headfour_subheadnine_paraone],
        },
      ],
    },
  ];
  return (
    <>
      <HelmetContainer metaData={sustainabilityCareers.metaData} />
      <SlideSection
        bgImage={MissionBg}
        title={parse(
          sustainabilityCareers.abtus_sust_mainhead_nxt + "<br>" + sustainabilityCareers.abtus_sust_mainhead,
        )}
        bgVariant={"gradient"}
        justify={"center"}
        titleAlign={"center"}
        titleWidth={"100%"}
        BgImageStyles={
          isMobile
            ? {
                backgroundRepeat: "no-repeat",
                backgroundPosition: "60%",
              }
            : {
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
              }
        }
      />
      <Section mainContent m={"9rem auto 19rem"}>
        <Wrap sx={{ alignSelf: "center" }}>
          <SustainabilitySecondTextHeading>{sustainabilityCareers.abtus_sust_mainhead}</SustainabilitySecondTextHeading>
        </Wrap>
        <SustainabilitySecondTextHeading>{sustainabilityCareers.abtus_sust_headone}</SustainabilitySecondTextHeading>
        <TextContent content={upperTextValue} />
        <SustainabilitySecondTextMainDiv>
          <SustainabilitySecondTextSecondaryDiv>
            <SustainabilitySecondTextImg src={homeIcon} />
            <SustainabilitySecondTextHeading>
              {sustainabilityCareers.abtus_sust_headone_subheadone}
            </SustainabilitySecondTextHeading>
            <SustainabilitySecondText>
              {sustainabilityCareers.abtus_sust_headone_subheadone_paraone}
            </SustainabilitySecondText>
          </SustainabilitySecondTextSecondaryDiv>
          <SustainabilitySecondTextSecondaryDiv>
            <SustainabilitySecondTextImg src={goDigital} />
            <SustainabilitySecondTextHeading>
              {sustainabilityCareers.abtus_sust_headone_subheadtwo}
            </SustainabilitySecondTextHeading>
            <SustainabilitySecondText>
              {sustainabilityCareers.abtus_sust_headone_subheadtwo_paraone}
            </SustainabilitySecondText>
          </SustainabilitySecondTextSecondaryDiv>
          <SustainabilitySecondTextSecondaryDiv>
            <SustainabilitySecondTextImg src={world} />
            <SustainabilitySecondTextHeading>
              {sustainabilityCareers.abtus_sust_headone_subheadthree}
            </SustainabilitySecondTextHeading>
            <SustainabilitySecondText>
              {sustainabilityCareers.abtus_sust_headone_subheadtwo_parathree}
            </SustainabilitySecondText>
          </SustainabilitySecondTextSecondaryDiv>
        </SustainabilitySecondTextMainDiv>
        <TextContent content={upperSecondTextValue} />

        <TextContent content={middleTextValue} />

        <SustainabilityTabMainDiv>
          <Wrap sx={{ textAlign: "center" }}>
            <SustainabilityTabHeading>
              {sustainabilityCareers.abtus_sust_headthree_tbl_colleft}
            </SustainabilityTabHeading>
            <SustainabilityTabBorderDiv year={true}>
              <SustainabilityTabHeading>
                {sustainabilityCareers.abtus_sust_headthree_tbl_collefttxt}
              </SustainabilityTabHeading>
            </SustainabilityTabBorderDiv>
          </Wrap>
          <Wrap>
            <Wrap sx={{ display: "flex", justifyContent: "space-between", padding: "0px 18rem 0px 6rem" }}>
              <SustainabilityTabHeading>
                {sustainabilityCareers.abtus_sust_headthree_tbl_colmidone}
              </SustainabilityTabHeading>
              <SustainabilityTabHeading>
                {sustainabilityCareers.abtus_sust_headthree_tbl_colrightone}
              </SustainabilityTabHeading>
            </Wrap>
            <SustainabilityTabBorderDiv year={false}>
              {performance.map((item) => {
                return (
                  <SustainabilityTabSecondaryDiv>
                    <SustainabilityTabText>{item.text}</SustainabilityTabText>
                    <Wrap sx={{ display: "flex", alignItems: "center", columnGap: "40px" }}>
                      <Wrap
                        sx={{
                          minWidth: "250px",
                          height: "20px",
                          background: "#3B3B3B",
                          border: "1px solid #7d848b",
                          borderRadius: "10px",
                        }}
                      >
                        <Wrap
                          sx={{
                            width: `${item.percentage}`,
                            background: "#9ca5ad",
                            height: "19px",
                            borderRadius: "10px",
                            borderEndEndRadius: "0px",
                            borderTopRightRadius: "0px",
                          }}
                        ></Wrap>
                      </Wrap>
                      <SustainabilityTabPerformance>{item.percentage}</SustainabilityTabPerformance>
                    </Wrap>
                  </SustainabilityTabSecondaryDiv>
                );
              })}
            </SustainabilityTabBorderDiv>
          </Wrap>
        </SustainabilityTabMainDiv>
        <TextContent content={bottomTextValue} />
      </Section>
    </>
  );
};
