import React, { useEffect } from "react";
import { BenefitItemsType } from "../../components/benefits/benefits.types";
import { Benefits } from "../../components/benefits/benefits.component";
import { ButtonArrow } from "../../components/button-arrow/button-arrow.component";
import MainBg from "../../assets/backgrounds/home-page/home_bg.svg";
import MapImage from "../../assets/backgrounds/home-page/map_image.png";
import { Section } from "../../components/section/section.component";
import { Wrap } from "../../components/wrap/wrap.component";
import { ReactComponent as FastIcon } from "../../assets/icons/benefits-main/fast.svg";
import { ReactComponent as GlobalIcon } from "../../assets/icons/benefits-main/global.svg";
import { ReactComponent as SafeIcon } from "../../assets/icons/benefits-main/safe.svg";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import SmartMockupBg from "../../assets/backgrounds/home-page/smart-mockup.png";
import { DownloadAppHeader } from "./main.styles";
import { useMediaQuery } from "../../utils/use-media-query";
import { uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";

import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const MainPage: React.FC = (): JSX.Element => {
  const { homePage } = useSelector(uiDataWebsiteText);
  const isMobile = useMediaQuery("sm");
  const handleClickDownloadApp = () => {
    window.open(homePage.hp_sectionThree_textlink, "noopener norefferer");
  };

  const handleClickRegisterOne = () => {
    window.open(homePage.hp_sectionOne_textlink, "_self");
  };
  const handleClickRegisterTwo = () => {
    window.open(homePage.hp_sectionTwo_textlink, "_self");
  };
  const benefitsList: BenefitItemsType = [
    {
      title: homePage.hp_head_left,
      description: homePage.hp_head_left_sub,
      icon: <SafeIcon />,
    },
    {
      title: homePage.hp_head_mid,
      description: homePage.hp_head_mid_sub,
      icon: <FastIcon />,
    },
    {
      title: homePage.hp_head_right,
      description: homePage.hp_head_right_sub,
      icon: <GlobalIcon />,
    },
  ];

  return (
    <>
      <HelmetContainer metaData={homePage.metaData} homePage />
      <SlideSection
        bgImage={MainBg}
        m={isMobile ? "4rem auto 9rem" : "4rem auto 4rem"}
        button={{
          title: homePage.hp_sectionOne_text,
          onClick: handleClickRegisterOne,
        }}
        title={homePage.hp_sectionOne_head}
        mobile={isMobile}
        titleWidth={isMobile ? "100%" : "50%"}
        BgImageStyles={
          !isMobile
            ? { backgroundSize: "cover", backgroundPosition: "right" }
            : {
                backgroundSize: "80rem",
                backgroundPosition: "100%",
              }
        }
      />
      <Section mainContent m={isMobile ? "10.5rem auto 0" : "8rem auto 8rem"}>
        <Benefits items={benefitsList} vertical={isMobile} />
      </Section>
      <Section mainContent m={"8rem auto 0"}>
        <Wrap sx={{ textTransform: "uppercase" }}>
          <h2>{homePage.hp_sectionTwo_head}</h2>
        </Wrap>
        <Wrap sx={{ width: "100%" }}>
          <img
            src={MapImage}
            alt={"Map"}
            style={{ position: "relative", width: "100%", margin: "9rem 0rem 7rem 0rem" }}
          />
        </Wrap>
        <Wrap sx={{ marginLeft: "auto", marginBottom: "6rem" }}>
          <ButtonArrow onClick={handleClickRegisterTwo}>{homePage.hp_sectionTwo_text}</ButtonArrow>
        </Wrap>
      </Section>
      <Section mainContent m={"8rem auto 12rem"}>
        <img
          src={SmartMockupBg}
          alt={"Download mobile app"}
          style={{ position: "relative", width: "100%", minHeight: "300px" }}
        />
        <Wrap
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            maxWidth: !isMobile ? "55%" : "100%",
            position: "absolute",
            justifyContent: "space-between",
            padding: !isMobile ? "4.7rem 9.2rem" : "2rem",
          }}
        >
          <DownloadAppHeader>{homePage.hp_sectionThree_head}</DownloadAppHeader>
          <ButtonArrow onClick={handleClickDownloadApp}>{homePage.hp_sectionThree_text}</ButtonArrow>
        </Wrap>
      </Section>
    </>
  );
};
