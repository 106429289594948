import styled from "styled-components";

export const MissionTabDiv = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1450px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const MissionTabMainBlock = styled.div<{ borderRight?: boolean; borderBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-right: ${(props) => (props.borderRight ? "1px solid #353535" : "")};
  border-bottom: ${(props) => (props.borderBottom ? "1px solid #353535" : "")};
  @media (max-width: 1450px) {
    border: none;
  }
`;
export const MissionTabHeading = styled.h1`
  color: white;
  font-size: 25px;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 992px) {
    line-height: 4rem;
    text-align: center;
  }
`;
export const MissionTabText = styled.p`
  color: #c8c8c8;
  font-size: 22px;
  align-items: center;
  margin: 40px 0px;
  @media (max-width: 992px) {
    margin: 25px 0px;
  }
`;
export const KeyMetricsDiv = styled.div`
  margin: 30px 0px 70px 0px;
  @media (max-width: 1450px) {
    margin: 0rem auto;
  }
`;
export const KeyMetricsMainDiv = styled.div`
  display: flex;
  @media (max-width: 1450px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const KeyMetricsContentDiv = styled.div<{ borderRight?: boolean; borderBottom?: boolean }>`
  display: flex;
  align-items: center;
  padding: 50px 60px;
  max-width: 500px;
  flex: 1;
  border-right: ${(props) => (props.borderRight ? "1px solid #353535" : "")};
  border-bottom: ${(props) => (props.borderBottom ? "1px solid #353535" : "")};
  justify-content: space-between;
  @media (max-width: 1450px) {
    border: none;
    padding: 25px 60px;
  }
`;
export const KeyMetricsMainText = styled.h1`
  color: white;
  font-weight: 700;
  font-size: 51px;
`;
export const KeyMetricsSecondaryText = styled.p`
  color: white;
  font-size: 24px;
`;
export const KeyMetricsYearsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 5rem 0px 20rem 0px;
  @media (max-width: 1200px) {
    margin-right: auto;
    width: auto;
  }
`;
export const KeyMetricsCircleDiv = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #505050;
`;
export const KeyMetricsHorizontalDiv = styled.div`
  height: 100px;
  width: 1px;
  border: 1px solid #505050;
`;
export const KeyMetricsTextDiv = styled.div<{ align: string; position: string }>`
  position: absolute;
  width: 400px;
  text-align: ${(props) => props.align};
  ${(props) => props.position}: 15%;
  @media (max-width: 1400px) {
    ${(props) => props.position}: 12%;
  }
  @media (max-width: 1200px) {
    left: 20%;
    right: 0;
    text-align: left;
    width: 70%;
  }
`;
export const KeyMetricsHeading = styled.h5`
  font-size: 28px;
  line-height: 2rem;
  margin-bottom: 8px;
`;
export const KeyMetricsText = styled.p`
  font-size: 20px;
  @media (max-width: 1200px) {
    text-align: justify;
  }
`;
export const KeyMetricsTableImg = styled.img`
  object-fit: contain;
  width: 150px;
  height: 150px;
`;
export const KeyMetricsImg = styled.img``;
export const KeyMetricsArrowImg = styled.img`
  width: 100px;
  height: 50px;
  margin: 0px 40px 45px 40px;
  align-self: center;
`;
