import { SlideSection } from "../../components/silde-section/slide-section.component";
import MissionBg from "../../assets/backgrounds/mission/home_slider.png";
import { Section } from "../../components/section/section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import { uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { IPageContent, TextContent } from "../../components/main-layout/text-content/text-content";
import parse from "html-react-parser";
import { Wrap } from "../../components/wrap/wrap.component";
import {
  CodeOFConductComa,
  CodeOFConductComaReverse,
  CodeOFConductHeading,
  CodeOFConductMessageDiv,
  CodeOFConductMessageForCEO,
  CodeOFConductVisionHeading,
  CodeOFConductVisionImg,
  CodeOFConductVisionImgDiv,
  CodeOFConductVisionMainDiv,
  CodeOFConductVisionText,
} from "./code-of-conduct.styles";
import come from "../../assets/icons/coma.svg";
import comeReverse from "../../assets/icons/coma_reverse.svg";
import values from "../../assets/icons/values.svg";
import integrity from "../../assets/icons/integrity.svg";
import performance from "../../assets/icons/performance.svg";
import centricity from "../../assets/icons/centricity.svg";
import innovation from "../../assets/icons/innovation.svg";
import discipline from "../../assets/icons/discipline.svg";
import partnership from "../../assets/icons/partnership.svg";
import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const ConductPage = () => {
  const { codeOfConduct } = useSelector(uiDataWebsiteText);
  const codeOfConductVision = [
    {
      image: values,
      heading: codeOfConduct.abtus_coc_headtwo_hdone,
      text: codeOfConduct.abtus_coc_headtwo_txtone,
      border: true,
    },
    {
      image: integrity,
      heading: codeOfConduct.abtus_coc_headtwo_hdtwo,
      text: codeOfConduct.abtus_coc_headtwo_txttwo,
      border: true,
    },
    {
      image: performance,
      heading: codeOfConduct.abtus_coc_headtwo_hdthree,
      text: codeOfConduct.abtus_coc_headtwo_txtthree,
      border: true,
    },
    {
      image: centricity,
      heading: codeOfConduct.abtus_coc_headtwo_hdfour,
      text: codeOfConduct.abtus_coc_headtwo_txtfour,
      border: true,
    },
    {
      image: innovation,
      heading: codeOfConduct.abtus_coc_headtwo_hdfive,
      text: codeOfConduct.abtus_coc_headtwo_txtfive,
      border: true,
    },
    {
      image: discipline,
      heading: codeOfConduct.abtus_coc_headtwo_hdsix,
      text: codeOfConduct.abtus_coc_headtwo_txtsix,
      border: true,
    },
    {
      image: partnership,
      heading: codeOfConduct.abtus_coc_headtwo_hdseven,
      text: codeOfConduct.abtus_coc_headtwo_txtseven,
      border: false,
    },
  ];
  const isMobile = useMediaQuery("sm");

  const textValue: IPageContent[] = [
    {
      mainTitle: codeOfConduct.abtus_coc_mainhead,
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_mainhead_paraone,
          article: [codeOfConduct.abtus_coc_mainhead_paratwo],
        },
      ],
    },
  ];
  const secondtextValue: IPageContent[] = [
    {
      title: codeOfConduct.abtus_coc_headtwo,
      article: [codeOfConduct.abtus_coc_headtwo_paraone],
    },
  ];
  const thirdtextValue: IPageContent[] = [
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headtwo_subheadone,
          article: [
            codeOfConduct.abtus_coc_headtwo_subheadone_paraone +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadone_paratwo,
          ],
        },
      ],
      article: [
        codeOfConduct.abtus_coc_headtwo_subheadone_parathree +
        " " +
        codeOfConduct.abtus_coc_headtwo_subheadone_parafour +
        " " +
        codeOfConduct.abtus_coc_headtwo_subheadone_parafive +
        " " +
        codeOfConduct.abtus_coc_headtwo_subheadone_parasix,
        codeOfConduct.abtus_coc_headtwo_subheadone_paraseven +
        " " +
        codeOfConduct.abtus_coc_headtwo_subheadone_paraeight,
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headtwo_subheadtwo,
          article: [codeOfConduct.abtus_coc_headtwo_subheadtwo_paraone],
        },
        {
          upperSubtitle: codeOfConduct.abtus_coc_headtwo_subheadthree,
          article: [
            codeOfConduct.abtus_coc_headtwo_subheadthree_paraone +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadthree_paratwo +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadthree_parathree +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadthree_parafour,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headtwo_subheadfour,
          article: [
            codeOfConduct.abtus_coc_headtwo_subheadfour_paraone +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadfour_paratwo +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadfour_parathree +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadfour_parafour,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headtwo_subheadfive,
          article: [
            codeOfConduct.abtus_coc_headtwo_subheadfive_paraone +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadfive_paratwo +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadfive_parathree,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headtwo_subheadsix,
          article: [
            codeOfConduct.abtus_coc_headtwo_subheadsix_paraone +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadsix_paratwo +
            " " +
            codeOfConduct.abtus_coc_headtwo_subheadsix_parathree,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headtwo_subheadseven,
          article: [codeOfConduct.abtus_coc_headtwo_subheadseven_paraone],
        },
      ],
    },
    {
      title: codeOfConduct.abtus_coc_headthree,
      article: [
        codeOfConduct.abtus_coc_headthree_paraone +
        " " +
        codeOfConduct.abtus_coc_headthree_paratwo +
        " " +
        codeOfConduct.abtus_coc_headthree_parathree,
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headthree_subheadone,
          article: [
            codeOfConduct.abtus_coc_headthree_subheadone_paraone +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadone_paratwo +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadone_parathree,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headthree_subheadtwo,
          article: [
            codeOfConduct.abtus_coc_headthree_subheadtwo_paraone +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadtwo_paratwo +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadtwo_parathree +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadtwo_parafour,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headthree_subheadthree,
          article: [
            codeOfConduct.abtus_coc_headthree_subheadthree_paraone +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadthree_paratwo +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadthree_parathree,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headthree_subheadfour,
          article: [codeOfConduct.abtus_coc_headthree_subheadfour_paraone],
        },
        {
          upperSubtitle: codeOfConduct.abtus_coc_headthree_subheadfive,
          article: [codeOfConduct.abtus_coc_headthree_subheadfive_paraone],
        },
        {
          upperSubtitle: codeOfConduct.abtus_coc_headthree_subheadsix,
          article: [codeOfConduct.abtus_coc_headthree_subheadsix_paraone],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headthree_subheadseven,
          article: [
            codeOfConduct.abtus_coc_headthree_subheadseven_paraone +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadseven_paratwo,
          ],
        },
      ],
      article: [
        codeOfConduct.abtus_coc_headthree_subheadseven_parathree +
        " " +
        codeOfConduct.abtus_coc_headthree_subheadseven_parafour +
        " " +
        codeOfConduct.abtus_coc_headthree_subheadseven_parafive +
        " " +
        codeOfConduct.abtus_coc_headthree_subheadseven_parasix +
        " " +
        codeOfConduct.abtus_coc_headthree_subheadseven_paraseven,
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headthree_subheadeight,
          article: [
            codeOfConduct.abtus_coc_headthree_subheadeight_paraone +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadeight_paratwo +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadeight_parathree +
            " " +
            codeOfConduct.abtus_coc_headthree_subheadeight_parafour,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headfour + "  " + codeOfConduct.abtus_coc_headfive,
          article: [codeOfConduct.abtus_coc_headfive_paraone],
        },
      ],
      article: [codeOfConduct.abtus_coc_headfive_paratwo + " " + codeOfConduct.abtus_coc_headfive_parathree],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headsix,
          article: [codeOfConduct.abtus_coc_headsix_paraone],
        },
        {
          upperSubtitle: codeOfConduct.abtus_coc_headseven,
          article: [
            codeOfConduct.abtus_coc_headseven_paraone +
            " " +
            codeOfConduct.abtus_coc_headseven_paratwo +
            " " +
            codeOfConduct.abtus_coc_headseven_parathree,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headeight,
          article: [codeOfConduct.abtus_coc_headeight_paraone + " " + codeOfConduct.abtus_coc_headeight_paratwo],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: codeOfConduct.abtus_coc_headnine,
          article: [codeOfConduct.abtus_coc_headnine_paraone],
        },
        {
          upperSubtitle: codeOfConduct.abtus_coc_headten,
          article: [
            codeOfConduct.abtus_coc_headten_paraone +
            " " +
            codeOfConduct.abtus_coc_headten_paratwo +
            " " +
            codeOfConduct.abtus_coc_headten_parathree +
            " " +
            codeOfConduct.abtus_coc_headten_parafour,
          ],
        },
      ],
    },
  ];

  return (
    <>
      <HelmetContainer metaData={codeOfConduct.metaData} />
      <SlideSection
        bgImage={MissionBg}
        title={parse(codeOfConduct.abtus_coc_mainhead_nxt + "<br>" + codeOfConduct.abtus_coc_mainhead)}
        bgVariant={"gradient"}
        justify={"center"}
        titleAlign={"center"}
        titleWidth={"100%"}
        BgImageStyles={
          isMobile
            ? {
              backgroundRepeat: "no-repeat",
              backgroundPosition: "60%",
            }
            : {
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }
        }
      />
      <Section mainContent m={"9rem auto 19rem"}>
        <TextContent content={textValue} />
        <Wrap>
          <CodeOFConductHeading>{codeOfConduct.abtus_coc_headone}</CodeOFConductHeading>
          <CodeOFConductMessageDiv>
            <CodeOFConductMessageForCEO>{codeOfConduct.abtus_coc_headone_paraone}</CodeOFConductMessageForCEO>
            <CodeOFConductComa src={come} />
            <CodeOFConductComaReverse src={comeReverse} />
          </CodeOFConductMessageDiv>
        </Wrap>
        <TextContent content={secondtextValue} />
        <Wrap sx={{ margin: "30px 0px" }}>
          {codeOfConductVision.map((codeOfConductVision) => {
            return (
              <CodeOFConductVisionMainDiv border={codeOfConductVision.border}>
                <CodeOFConductVisionImgDiv>
                  <CodeOFConductVisionImg src={codeOfConductVision.image} />
                </CodeOFConductVisionImgDiv>
                <Wrap>
                  <CodeOFConductVisionHeading>{codeOfConductVision.heading}</CodeOFConductVisionHeading>
                  <CodeOFConductVisionText>{codeOfConductVision.text}</CodeOFConductVisionText>
                </Wrap>
              </CodeOFConductVisionMainDiv>
            );
          })}
        </Wrap>
        <TextContent content={thirdtextValue} />
      </Section>
    </>
  );
};
