import styled from "styled-components";

export const ComplaintsPolicyHeading = styled.h1`
    color :white;
    font-size: 40px;
    font-weight : 600
`;

export const ComplaintHeading = styled.p`
    color:white;
    font-size: 16px;
    margin-top: 109px;
    font-weight: 300
`
export const ComplaintContent = styled.p`
    color : #A1A1A1;
    margin-top : 4rem;
    font-weight: 300

`;