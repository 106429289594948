import styled from "styled-components";

export const SustainabilitySecondTextMainDiv = styled.div`
  display: flex;
  column-gap: 90px;
  margin: 60px 0px 50px 0px;
  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;
export const SustainabilitySecondTextSecondaryDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const SustainabilitySecondTextImg = styled.img`
  width: 170px;
  height: 170px;
`;
export const SustainabilitySecondTextHeading = styled.h3`
  font-size: 28px;
  margin: 40px 0px 10px 0px;
  @media (max-width: 1200px) {
    margin: 20px 0px 10px 0px;
  }
`;
export const SustainabilitySecondText = styled.p`
  font-size: 20px;
  @media (max-width: 1200px) {
    text-align: justify;
  }
`;
export const SustainabilityTabMainDiv = styled.div`
  display: flex;
  column-gap: 30px;
  width: 100%;
  margin: 50px 0px 100px 0px;
`;
export const SustainabilityTabBorderDiv = styled.div<{ year: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => (props.year ? "0px 100px" : "0px 50px")};
  border: 1px solid #7d848b;
  border-radius: 10px;
  height: 100%;
  margin-top: 30px;
`;
export const SustainabilityTabSecondaryDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
`;
export const SustainabilityTabHeading = styled.h5`
  font-size: 28px;
`;
export const SustainabilityTabProgress = styled.progress`
  height: 40px;
  accent-color: #9ca5ad;
  margin-right: 40px;
  width: 280px;
  min-width: 250px;
`;
export const SustainabilityTabPerformance = styled.p`
  color: white;
  font-size: 28px;
`;
export const SustainabilityTabText = styled.p`
  color: white;
  font-size: 20px;
  width: 60%;
`;
