import React, { MouseEventHandler, useMemo, useState } from "react";
import { Input } from "../input/input.component";
import { SelectOption, SelectProps, SimpleSelectEvent } from "./select.types";
import { Sel } from "./select.styles";
import ClickAwayListener from "react-click-away-listener";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/icons/arrow_up.svg";
import { Divider } from "../../divider/divider.styles";
import { Wrap } from "../../wrap/wrap.component";
import { useSelector } from "../../../redux/store";
import { uiDataWebsiteText } from "../../../redux/uiData/selectors";

export const Select: React.FC<SelectProps> = (props) => {
  const { aboutUsCareers } = useSelector(uiDataWebsiteText);
  const [showOptions, setShowOptions] = useState<boolean>();
  const {
    value,
    onSelect,
    options,
    optionsPosition = "bottom",
    borderRadius,
    border,
    label,
    InputProps,
    fullWidth,
    name,
    maxWidth,
    borderColor,
    noAdornment,
    error,
    helperText,
    disabled,
    paddingFull,
  } = props;

  const handleCloseList = () => {
    setShowOptions(false);
  };

  const handleOpenList = () => {
    setShowOptions(true);
  };

  const handleSelect =
    (option: SelectOption): MouseEventHandler<HTMLDivElement> =>
    () => {
      const simpleEvent: SimpleSelectEvent = {
        target: {
          name,
          value: option,
        },
      };
      onSelect(simpleEvent);
      setShowOptions(false);
    };

  const getOptionsWithUpArrow = useMemo(() => {
    const arrowPositionIndex = optionsPosition === "bottom" ? 0 : options.length - 1;
    return options.map((option, index) =>
      index !== arrowPositionIndex ? option : { ...option, startIcon: <ArrowUpIcon onClick={handleCloseList} /> },
    );
  }, []);

  return (
    <ClickAwayListener onClickAway={handleCloseList}>
      <Sel.Root fullWidth={fullWidth} maxWidth={maxWidth}>
        <Input
          border={border}
          cursor={"pointer"}
          value={value?.abbr || value?.label || ""}
          InputNativeProps={{ readOnly: true, onFocus: handleOpenList }}
          onStartIconClick={handleOpenList}
          borderRadius={borderRadius}
          label={label}
          fullWidth={fullWidth}
          borderColor={borderColor}
          error={error}
          helperText={helperText}
          disabled={disabled}
          _select
          startIcon={
            <Sel.Adornment.Root>
              <ArrowDownIcon />
              {!value && !noAdornment && (
                <Sel.Adornment.Label style={{ width: "100px" }}>
                  {aboutUsCareers.abtus_cr_searchsection_labelnine}
                </Sel.Adornment.Label>
              )}
            </Sel.Adornment.Root>
          }
          {...InputProps}
        />
        {showOptions && (
          <Sel.Options.Root
            optionsPosition={optionsPosition}
            borderRadius={borderRadius}
            border={border}
            fullWidth={fullWidth}
          >
            {getOptionsWithUpArrow.map((option, index) => (
              <Wrap key={`option-item-${index}`} sx={{ width: "100%", alignItems: "center" }}>
                <Sel.Options.Item.Root paddingFull={paddingFull}>
                  {option.startIcon && (
                    <Wrap sx={{ padding: "1rem 1rem 1rem 0" }}>
                      <Sel.Options.Item.Icon>{option.startIcon}</Sel.Options.Item.Icon>
                    </Wrap>
                  )}
                  <Sel.Options.Item.Title onClick={handleSelect(option)}>{option.label}</Sel.Options.Item.Title>
                </Sel.Options.Item.Root>
                {index !== options.length - 1 && <Divider variant="solid" width={"90%"} />}
              </Wrap>
            ))}
          </Sel.Options.Root>
        )}
      </Sel.Root>
    </ClickAwayListener>
  );
};
