import styled from "styled-components";
import { media } from "../../utils/use-media-query";
import { LeaderShipProfileType } from "../../api/types/fetch.ui.types";

export const LeadershipContentContainer = styled.div`
  width: 100%;
`;
export const LeadershipContent = styled.div<{ $isMobile?: boolean }>`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  @media (${media.lg}) {
    padding: 0 15rem;
  }

  @media (${media.md}) {
    padding: 0 10rem;
  }

  @media (${media.sm}) {
    padding: 0 2rem;
  }
`;
export const LeadershipCenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeadershipHeading = styled.h4`
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 3.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.9rem;
  text-transform: uppercase;
  margin-bottom: 6.2rem;
  margin-top: 1.8rem;
`;

export const LeadershipBoardofDirectorsHeading = styled.h4<{ $isMobile?: boolean }>`
  color: #fff;
  font-family: "Open Sans";
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.9rem;
  margin: 3rem 0;
  padding: ${(props: any) => (props.$isMobile ? "0px 17px 0px 17px" : "0px")};
`;

export const LayoutDivider = styled.div`
  background: #4d4d4d;
  height: 1px;
  width: 100%;
  margin: 0rem auto;
`;

export const LeadershipBoardOfDirectorsContent = styled.div``;

export const LeadershipCardGridContainer = styled.div<{ $isMobile?: boolean }>`
  gap: ${(props: any) => (props.$isMobile ? "1.8em 0em" : "3em 0em")};
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  grid-col-gap: 1.6rem;
  grid-row-gap: 1.6rem;
`;

/*
  display: grid;
  grid-template-columns: 100%;
  gap: 0em 0em;
  grid-template-rows: unset;
  grid-template-areas:
    "leadership_image"
    "leadership_name"
    "leadership_title";
  width: 300px;
  border: 1px solid red;
  grid-auto-rows: max-content;
  margin-bottom: 1rem;
*/

export const CardContainer = styled.div<{ $isMobile?: boolean }>`
  cursor: pointer;
  padding: ${(props: any) => (props.$isMobile ? "0px" : "0px")};
  margin-bottom: 1rem;
  justify-content: center;
`;

export const CardImage = styled.img`
  grid-area: leadership_image;
  width: 100%;
  height: 233px;
  border-radius: 5px;
  object-fit: cover;
  object-position: top;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(138, 138, 138, 0.2) 100%);
`;

export const CardName = styled.p`
  grid-area: leadership_name;
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  height: fit-content;
  margin-top: 1rem;
`;

export const CardTitle = styled.p`
  grid-area: leadership_title;
  font-family: Open Sans;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
`;

export const CardDepartment = styled.p`
  grid-area: leadership_department;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
`;

export const DirectorCardContainer = styled.div<{ $isMobile?: boolean; $type: LeaderShipProfileType }>`
  cursor: ${(props: any) => props.$type !== "0" && "pointer"};
  display: grid;
  justify-content: center;
  grid-template-columns: 100%;
  grid-template-rows: unset;
  grid-template-areas:
    "leadership_name"
    "leadership_title";
  grid-auto-rows: max-content;
  margin-bottom: 3rem;
  padding: ${(props: any) => (props.$isMobile ? "0px 17px 0px 17px" : "0px")};
`;

export const DirectorsCardGridContainer = styled.div<{ $isMobile?: boolean }>`
  gap: ${(props: any) => (props.$isMobile ? "1.8em 0em" : "3em 0em")};
  justify-content: space-between;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  margin-top: 3rem;
  margin-bottom: 5rem;
`;

export const DirectorCardName = styled.p`
  grid-area: leadership_name;
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  height: fit-content;
  margin-top: 0rem;
`;

export const DirectorCardTitle = styled.p`
  grid-area: leadership_title;
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 400;
  max-width: 250px;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
`;

export const Leadership = {
  Container: LeadershipContentContainer,
  Content: LeadershipContent,
  Header: LeadershipHeading,
  BodHeader: LeadershipBoardofDirectorsHeading,
  Divider: LayoutDivider,
  BodContent: LeadershipBoardOfDirectorsContent,
  CardGridContainer: LeadershipCardGridContainer,
  CardContainer: CardContainer,
  CardImage: CardImage,
  CardName: CardName,
  CardTitle: CardTitle,
  CardDepartment: CardDepartment,
  DirectorCardContainer: DirectorCardContainer,
  DirectorsCardGridContainer: DirectorsCardGridContainer,
  DirectorCardName: DirectorCardName,
  DirectorCardTitle: DirectorCardTitle,
};
